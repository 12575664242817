/*eslint-disable*/
import React from "react";
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionLatestOffers from "views/PresentationPage/Sections/SectionLatestOffers.js";
import SectionBlog from "views/PresentationPage/Sections/SectionBlog.js";
import SectionHeaders from "views/PresentationPage/Sections/SectionHeaders.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(styles);

export default function PresentationPage() {

  /**Fixed For Each Page */
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  return (
    <div>
      <Header
        brand={t('page.header.title')}
        links={<HeaderLinks  />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
      />

      <SectionHeaders />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionBlog />
        <SectionLatestOffers />
      </div>

      <Footer/>
    </div>
  );
}
