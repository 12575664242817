/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// react components for routing our app without refresh
import { useNavigate, useSearchParams } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CheckCircle from "@material-ui/icons/CheckCircle";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/FooterV2";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// image
import image from "assets/img/bg7.jpg";

// style
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import stylesInfoArea from "assets/jss/material-kit-pro-react/components/infoStyle.js";

const useStyles = makeStyles(signupPageStyle);
const useStylesInfoArea = makeStyles(stylesInfoArea);

export default function CheckoutFinsihPage() {

  /**Fixed For Each Page */
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  const classesInfoArea = useStylesInfoArea();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (searchParams.get("orderId") === undefined || searchParams.get("orderId") === null) {
      navigate("/");
    }
  }, [searchParams]);

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand={t('page.header.title')}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={5} md={12}>
                      <div className={classes.textCenter}>
                        <div className={classNames({
                          [classesInfoArea.infoArea]: true,
                          [classes.infoArea]: classes.infoArea
                        })}
                        >
                          <div className={classNames({
                            [classesInfoArea.iconWrapper]: true,
                            [classesInfoArea["success"]]: true,
                            [classesInfoArea.iconWrapperVertical]: true
                          })}>
                            <CheckCircle className={classNames({
                              [classesInfoArea.icon]: true,
                              [classesInfoArea.iconVertical]: true
                            })} />
                          </div>
                          <div className={classesInfoArea.descriptionWrapper}>
                            <h4 className={classesInfoArea.title}>{t('page.checkoutFinish.title')}</h4>
                            <div className={classesInfoArea.description}>
                              <b> {t('page.checkoutFinish.desc') + " :"} {searchParams.get("orderId")}</b>
                              <p>&nbsp;</p>
                            </div>
                            <a
                              href="/siparislerim"
                              className={classes.block}
                            >
                              <i
                                className={classes.socialIcons + " " + classes.marginRight5 + " fa fa-home"}
                              />
                              {t('page.checkoutFinish.gotoOrders')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer color={"white"} />
      </div>
    </div>
  );
}
