/* eslint-disable */
import React, { useCallback, useReducer } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Badge from "@material-ui/core/Badge";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import LineStyle from "@material-ui/icons/LineStyle";
import ShoppingCart from "@material-ui/icons/ShoppingCart";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

import { useBasket } from "store/basketContext";
import { useAuth } from "store/authContext";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

  const { detech } = useBasket();
  const { authState, logoutHandler } = useAuth();

  const { t } = useTranslation();
  const { dropdownHoverColor, disableBasket } = props;

  const classes = useStyles();

  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const handleBasketRequest = async () => {
    try {
      const response = await api.get('/basket');
      console.log('Post Response Get 1:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Post Error:', error);
      setError(error);
    }
  };

  React.useEffect(() => {
    if (!disableBasket) {
      handleBasketRequest();
    }
  }, [detech]);

  return (<>
    <List className={classes.list + " " + classes.mlAuto}>
      {authState?.loggedIn ?
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText={t('page.header.account')}
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Person}
            dropdownList={[
              <Link to="/siparislerim#orders" className={classes.dropdownLink}>
                <LineStyle className={classes.dropdownIcons} /> Siparişlerim
              </Link>,
              <Link to="/hesabim#address" className={classes.dropdownLink}>
                <LineStyle className={classes.dropdownIcons} /> Kullanıcı Bilgilerim
              </Link>,
              <Link to={location} className={classes.dropdownLink} onClick={e => logoutHandler()}>
                <LineStyle className={classes.dropdownIcons} /> Çıkış Yap
              </Link>
            ]}
          />
        </ListItem>
        :
        <ListItem className={classes.listItem}>
          <Button
            href={location.pathname.includes('giris') || location.pathname.includes('uyeol') ? "/giris?callbackurl=" + location.origin : "/giris?callbackurl=" + location}
            color={window.innerWidth < 960 ? "transparent" : "transparent"}
            className={classes.navLink}
          >

            <Person className={classes.icons} />
            {t('page.header.login')}
          </Button>
        </ListItem>
      }
      {
        disableBasket ?
          <ListItem className={classes.listItem}>
            <Button
              href="/sepetim"
              color={window.innerWidth < 960 ? "info" : "white"}
              className={classes.navButton}
              round
            >
              <ShoppingCart className={classes.icons} />
              &nbsp; &nbsp;  &nbsp;
              {t('page.header.basket')}
            </Button>
          </ListItem>
          :
          <ListItem className={classes.listItem}>
            <Button
              href="/sepetim"
              color={window.innerWidth < 960 ? "info" : "white"}
              className={classes.navButton}
              round
            >
              <Badge badgeContent={data?.lineItems.length} color="secondary" overlap="rectangular">
                <ShoppingCart className={classes.icons} />
              </Badge>&nbsp; &nbsp;  &nbsp;
              {t('page.header.basket')}
            </Button>
          </ListItem>
      }
    </List>
  </>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
