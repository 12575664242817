/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";
import SectionProduct from "./SectionProduct";

const useStyles = makeStyles(styles);

export default function SectionProducts({ path, name, firstProductModels, firstTotalItems, forwardRefReset, resetTotalItems }) {

  const { t } = useTranslation();
  const classes = useStyles();

  forwardRefReset((filterItems) => {
    setFilterItems(filterItems);
    handlePageContextRequest(0, filterItems);
  });

  const handleLoadMore = (page, totalItems) => {
    return (page + 1) * 9 < totalItems;
  }

  const [productModels, setProductModels] = React.useState([...firstProductModels]);
  const [page, setPage] = React.useState(0);
  const [totalItems, setTotalItems] = React.useState(firstTotalItems);
  const [loadMore, setLoadMore] = React.useState(handleLoadMore(0, firstTotalItems));
  const [filterItems, setFilterItems] = React.useState();

  const handlePageContextRequest = async (requestPage, filterItems = {}) => {
    console.log("path, name", path, name);
    let url;
    if (name) {
      url = '/page/context/category?name=' + name + '&page=' + requestPage;
    } else {
      url = '/page/context/category?path=' + path + '&page=' + requestPage;
    }
    try {
      const response = await api.post(url, filterItems);
      console.log('Post Response:', response.data);
      if (response.data?.searchResponse?.products) {
        setPage(response.data.page);
        setLoadMore(handleLoadMore(response.data.page, response.data.searchResponse.totalItems));
        setTotalItems(response.data.searchResponse.totalItems);
        if (resetTotalItems) {
          resetTotalItems(response.data.searchResponse.totalItems);
        }
        if (requestPage === 0) {
          setProductModels(response.data.searchResponse.products);
        } else {
          setProductModels(productModels.concat(response.data.searchResponse.products));
        }
      } else {
        setLoadMore(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Not Found Error:', error.response.data);
      } else {
        console.error('Post Error:', error);
      }
    }
  };

  return (
    <GridContainer>
      {productModels.map(p =>
        <GridItem md={4} sm={4} key={p.id}>
          <SectionProduct productModel={p} />
        </GridItem>
      )}
      {loadMore && productModels?.length > 0 ?
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mlAuto, classes.mrAuto)}
        >
          <Button round color="rose" onClick={() => handlePageContextRequest(page + 1, filterItems)}>
            {t('page.category.product.loadMore')}
          </Button>
        </GridItem>
        :
        null
      }
    </GridContainer>
  );
}
