import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui icons
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle.js";

import emptyImage from "assets/img/examples/suit-4.png";

const useStyles = makeStyles(styles);

export default function SectionProduct({ productModel }) {

  if (!productModel) {
    return null;
  }

  const classes = useStyles();

  const product = productModel.products.find(p => p.psi.bestPrice === true);
  const psi = product?.psi;
  const images = productModel.productModelImages.length > 0 ? productModel.productModelImages[0] : emptyImage;

  return (
    <Card plain product={true}>
      <CardHeader noShadow image
        style={{ minWidth: "225px", minHeight: "234px" }}
      >
        <a href={"/" + productModel.url}>
          <img src={images} alt=".." />
        </a>
      </CardHeader>
      <CardBody plain>
        <a href="/urun-x">
          <h4 className={classes.cardTitle}>{""}</h4>
        </a>
        <p className={classes.descriptionName}>
          {productModel.name}
        </p>
      </CardBody>
      <CardFooter plain className={classes.justifyContentBetween}>
        <div className={classes.priceContainer}>
          {psi && psi.listPrice && psi.listPrice !== psi.price ?
            <>
              <span className={classNames(classes.price, classes.priceOld)}>
                &nbsp;
                {psi.listPrice + " TL"}
                &nbsp;
              </span>
              <span className={classNames(classes.price, classes.priceNew)}>
                &nbsp;
                {psi.price + " TL"}
              </span>
            </>
            :
            <span className={classes.price}> {psi ? psi.price + " TL" : t('page.category.product.outOfStock')}</span>
          }
        </div>
        <div className={classes.priceContainer}>
        </div>
        <Tooltip
          id="tooltip-top"
          title="Save to Wishlist"
          placement="left"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            justIcon
            simple
            color="rose"
            className={classes.pullRight}
          >
            <FavoriteBorder />
          </Button>
        </Tooltip>
      </CardFooter>
    </Card>
  );
}
