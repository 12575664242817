/* eslint-disable */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
// I18N
import {useTranslation} from 'react-i18next';
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps";
import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";

import contactUsStyle from "assets/jss/material-kit-pro-react/views/contactUsStyle.js";
import api from "../../api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const CustomSkinMap = withScriptjs(
    withGoogleMap(() => (
        <GoogleMap
            defaultZoom={14}
            defaultCenter={{lat: 44.43353, lng: 26.093928}}
            defaultOptions={{
                scrollwheel: false,
                zoomControl: true,
                styles: [
                    {
                        featureType: "water",
                        stylers: [
                            {saturation: 43},
                            {lightness: -11},
                            {hue: "#0088ff"}
                        ]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: "#ff0000"},
                            {saturation: -100},
                            {lightness: 99}
                        ]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{color: "#808080"}, {lightness: 54}]
                    },
                    {
                        featureType: "landscape.man_made",
                        elementType: "geometry.fill",
                        stylers: [{color: "#ece2d9"}]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry.fill",
                        stylers: [{color: "#ccdca1"}]
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{color: "#767676"}]
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.stroke",
                        stylers: [{color: "#ffffff"}]
                    },
                    {featureType: "poi", stylers: [{visibility: "off"}]},
                    {
                        featureType: "landscape.natural",
                        elementType: "geometry.fill",
                        stylers: [{visibility: "on"}, {color: "#b8cb93"}]
                    },
                    {featureType: "poi.park", stylers: [{visibility: "on"}]},
                    {
                        featureType: "poi.sports_complex",
                        stylers: [{visibility: "on"}]
                    },
                    {featureType: "poi.medical", stylers: [{visibility: "on"}]},
                    {
                        featureType: "poi.business",
                        stylers: [{visibility: "simplified"}]
                    }
                ]
            }}
        >
            <Marker position={{lat: 44.43353, lng: 26.093928}}/>
        </GoogleMap>
    ))
);

const useStyles = makeStyles(contactUsStyle);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ContactUsPage() {

    /**Fixed For Each Page */
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });

    const classes = useStyles();
    const {t} = useTranslation();
    /**Fixed For Each Page */

    const [openSuccessNotify, setOpenSuccessNotify] = React.useState(false);
    const [openErrorNotify, setOpenErrorNotify] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [postData, setPostData] = React.useState({
        contactInfo: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleResetForm = () => {
        setPostData({
            contactInfo: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        });
    };

    const handlePostRequest = async () => {
        setLoading(true);
        try {
            const response = await api.post('/contact-us', postData);
            console.log('Post Response:', response.data);
            console.log(postData);
            setOpenSuccessNotify(true);
            handleResetForm();
        } catch (error) {
            console.error('Post Error:', error);
            setError(error);
            setOpenErrorNotify(true);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setPostData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSuccessCloseNotify = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessNotify(false);
    };

    const handleErrorCloseNotify = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorNotify(false);
    };

    return (
        <div>
            <Snackbar open={openSuccessNotify} autoHideDuration={3000} onClose={handleSuccessCloseNotify}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert onClose={handleSuccessCloseNotify} severity="success">
                    {t('page.contact.success')}
                </Alert>
            </Snackbar>
            <Snackbar open={openErrorNotify} autoHideDuration={3000} onClose={handleErrorCloseNotify}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert onClose={handleErrorCloseNotify} severity="error">
                    {t('page.contact.error')}
                </Alert>
            </Snackbar>
            <Header
                brand={t('page.header.title')}
                links={<HeaderLinks dropdownHoverColor="dark"/>}
                fixed
                color="dark"
            />
            <div className={classes.bigMap}>
                <CustomSkinMap
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={
                        <div
                            style={{
                                height: `100%`,
                                borderRadius: "6px",
                                overflow: "hidden"
                            }}
                        />
                    }
                    mapElement={<div style={{height: `100%`}}/>}
                />
            </div>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.contactContent}>
                    <div className={classes.container}>
                        <h2 className={classes.title}>{t('page.contact.title')}</h2>
                        <GridContainer>
                            <GridItem md={6} sm={6}>
                                <p>
                                    {t('page.contact.desc')}
                                    <br/>
                                    <br/>
                                </p>
                                <form>
                                    <CustomInput
                                        id="contactInfo"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            placeholder: t('page.contact.contactInfo'),
                                            type: "text",
                                            name: "contactInfo",
                                            autoComplete: "off",
                                            value: postData.contactInfo,
                                            onChange: handleChange
                                        }}
                                    />

                                    <CustomInput
                                        id="email"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            placeholder: t('page.contact.email'),
                                            type: "email",
                                            name: "email",
                                            value: postData.email,
                                            onChange: handleChange
                                        }}
                                    />

                                    <CustomInput
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            placeholder: t('page.contact.phone'),
                                            type: "text",
                                            name: "phone",
                                            autoComplete: "off",
                                            value: postData.phone,
                                            onChange: handleChange
                                        }}
                                    />

                                    <CustomInput
                                        id="subject"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            placeholder: t('page.contact.subject'),
                                            type: "text",
                                            name: "subject",
                                            autoComplete: "off",
                                            value: postData.subject,
                                            onChange: handleChange
                                        }}
                                    />

                                    <CustomInput
                                        id="message"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            placeholder: t('page.contact.message'),
                                            name: "message",
                                            multiline: true,
                                            rows: 6,
                                            value: postData.message,
                                            onChange: handleChange
                                        }}
                                    />

                                    <div className={classes.textCenter}>
                                        <Button round color="primary" disabled={loading} onClick={handlePostRequest}>
                                            {loading ?
                                                (
                                                    <>
                                                        <i className="fas fa-spinner fa-spin"/> &nbsp;  &nbsp;
                                                    </>
                                                )
                                                : null
                                            }
                                            {t('page.contact.send')}
                                        </Button>
                                    </div>
                                </form>
                            </GridItem>
                            <GridItem md={4} sm={4} className={classes.mlAuto}>
                                <InfoArea
                                    className={classes.info}
                                    title="Find us at the office"
                                    description={
                                        <p>
                                            Bld Mihail Kogalniceanu, nr. 8, <br/> 7652 Bucharest,{" "}
                                            <br/> Romania
                                        </p>
                                    }
                                    icon={PinDrop}
                                    iconColor="primary"
                                />
                                <InfoArea
                                    className={classes.info}
                                    title="Give us a ring"
                                    description={
                                        <p>
                                            Michael Jordan <br/> +40 762 321 762 <br/> Mon - Fri,
                                            8:00-22:00
                                        </p>
                                    }
                                    icon={Phone}
                                    iconColor="primary"
                                />
                                <InfoArea
                                    className={classes.info}
                                    title="Legal Information"
                                    description={
                                        <p>
                                            Creative Tim Ltd. <br/> VAT · EN2341241 <br/> IBAN ·
                                            EN8732ENGB2300099123 <br/> Bank · Great Britain Bank
                                        </p>
                                    }
                                    icon={BusinessCenter}
                                    iconColor="primary"
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}