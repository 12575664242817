/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// DOM
import { useNavigate } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// sections for this page
import SectionInformation from "views/CheckoutPage/Sections/SectionInformation/SectionInformation.js";
import SectionInstallment from "views/CheckoutPage/Sections/SectionInstallment/SectionInstallment.js";
import SectionProduct from "views/CheckoutPage/Sections/SectionProduct/SectionProduct.js";
import SectionAgreement from "views/CheckoutPage/Sections/SectionAgreement/SectionAgreement.js";
import SectionPayment from "views/CheckoutPage/Sections/SectionPayment/SectionPayment.js";

// style
import styles from "assets/jss/material-kit-pro-react/views/checkoutStyle.js";

const useStyles = makeStyles(styles);
import { useAuth } from "store/authContext";

export default function CheckoutPage() {

  /**Fixed For Each Page */
  React.useEffect(() => {
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  const { authState } = useAuth();
  const navigate = useNavigate();

  if (!authState?.loggedIn) {
    navigate("/");
  }

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const handleBasketRequest = async () => {
    try {
      setLoading(true);
      const response = await api.get('/basket');
      setData(response.data);
    } catch (error) {
      console.error('Post Error:', error);
      setData({ lineItems: [] });
      setError(error);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    handleBasketRequest();
  }, []);

  let getAddressDelivery = () => { };
  let getAddressInvoice = () => { };
  let getPaymentOption = () => { };
  let getInstallment = () => { };

  const [formValue, setFormValue] = React.useState({});

  const handleChange = () => {
    setFormValue({
      deliveryAddress: getAddressDelivery(),
      invoiceAddress: getAddressInvoice(),
      paymentOption: getPaymentOption(),
      installment: getInstallment()
    })
  }

  return (
    <div>
      <Header
        brand={t('page.header.title')}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "info"
        }}
      />

      <div className={classNames(classes.appLayout)}>
        <div className={classNames(classes.mainContainer)}>
          <section className={classNames(classes.mainSection)}>
            <div className={classNames(classes.main, classes.mainRaisedFirst, classes.mainContainerCheckoutTeslimat)}>
              <SectionInformation handleChange={handleChange} forwardRefDelivery={c => { getAddressDelivery = c }} forwardRefInvoice={c => { getAddressInvoice = c }} />
            </div>

            <div className={classNames(classes.main, classes.mainRaised, classes.mainContainerCheckoutTeslimat)}>
              <SectionInstallment handleChange={handleChange} forwardRefPaymentOption={c => { getPaymentOption = c }} forwardRefInstallment={c => { getInstallment = c }} />
            </div>

            <div className={classNames(classes.main, classes.mainRaised, classes.mainContainerCheckoutTeslimat)}>
              <SectionProduct lineItems={data?.lineItems} />
            </div>

            <div className={classNames(classes.main, classes.mainRaised, classes.mainContainerCheckoutAgreement)}>
              <SectionAgreement />
            </div>
          </section>
          <div className={classNames(classes.main, classes.mainRaisedFirst, classes.mainAside)}>
            <SectionPayment
              shipmentPrice={data?.shipmentPrice}
              totalPrice={data?.totalPrice}
              basketTotal={data?.basketTotal}
              deliveryAddress={formValue.deliveryAddress}
              invoiceAddress={formValue.invoiceAddress}
              paymentOption={formValue.paymentOption}
              installment={formValue.installment}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
