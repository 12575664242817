/*eslint-disable*/
import React, { useCallback, useReducer } from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";


import ProductPageLastVisitProduct from "./ProductPageLastVisitProduct";

const useStyles = makeStyles(productStyle);

export default function ProductPageLastVisit({ url }) {

  /**Fixed For Each Page */
  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  if (url) {
    const storageKey = 'recentProducts';
    let recentProducts = JSON.parse(localStorage.getItem(storageKey)) || [];

    const newProductUrl = url;
    if (!recentProducts.includes(newProductUrl)) {
      recentProducts.push(newProductUrl);
    }

    if (recentProducts.length >= 6) {
      recentProducts = recentProducts.slice(1, 7);
    }
    localStorage.setItem(storageKey, JSON.stringify(recentProducts));
  }


  const [productOne, setProductOne] = React.useState(null);
  const [productTwo, setProductTwo] = React.useState(null);
  const [productThree, setProductThree] = React.useState(null);
  const [productFour, setProductFour] = React.useState(null);

  const handlePageContextRequestOne = async (url) => {
    if (url) {
      try {
        const response = await api.get('/page/context?path=' + url);
        console.log('Post Response:', response.data?.searchResponse?.products[0]);
        setProductOne(response.data?.searchResponse?.products[0]);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error('Not Found Error:', error.response.data);
        } else {
          console.error('Post Error:', error);
        }
      }
    }
  };

  const handlePageContextRequestTwo = async (url) => {
    if (url) {
      try {
        const response = await api.get('/page/context?path=' + url);
        console.log('Post Response:', response.data?.searchResponse?.products[0]);
        setProductTwo(response.data?.searchResponse?.products[0]);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error('Not Found Error:', error.response.data);
        } else {
          console.error('Post Error:', error);
        }
      }
    }
  };

  const handlePageContextRequestThree = async (url) => {
    if (url) {
      try {
        const response = await api.get('/page/context?path=' + url);
        console.log('Post Response:', response.data?.searchResponse?.products[0]);
        setProductThree(response.data?.searchResponse?.products[0]);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error('Not Found Error:', error.response.data);
        } else {
          console.error('Post Error:', error);
        }
      }
    }
  };

  const handlePageContextRequestFour = async (url) => {
    if (url) {
      try {
        const response = await api.get('/page/context?path=' + url);
        console.log('Post Response:', response.data?.searchResponse?.products[0]);
        setProductFour(response.data?.searchResponse?.products[0]);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error('Not Found Error:', error.response.data);
        } else {
          console.error('Post Error:', error);
        }
      }
    }
  };

  React.useEffect(() => {
    const storageKey = 'recentProducts';
    let recentProducts = JSON.parse(localStorage.getItem(storageKey)) || [];
    handlePageContextRequestOne(recentProducts[3]);
    handlePageContextRequestTwo(recentProducts[2]);
    handlePageContextRequestThree(recentProducts[1]);
    handlePageContextRequestFour(recentProducts[0]);
  }, []);

  return (
    <>
      {productOne || productTwo || productThree || productFour ?
        <div className={classes.relatedProducts}>
          <h3 className={classNames(classes.title, classes.textCenter)}>
            {t('page.product.lastVisit.title')}
          </h3>
          <GridContainer>
            {productOne ?
              <GridItem sm={6} md={3} key={productOne?.id}>
                <ProductPageLastVisitProduct productModel={productOne} />
              </GridItem>
              :
              null
            }
            {productTwo ?
              <GridItem sm={6} md={3} key={productTwo?.id} >
                <ProductPageLastVisitProduct productModel={productTwo} />
              </GridItem>
              :
              null
            }
            {productThree ?
              <GridItem sm={6} md={3} key={productThree?.id}>
                <ProductPageLastVisitProduct productModel={productThree} />
              </GridItem>
              :
              null
            }
            {productFour ?
              <GridItem sm={6} md={3} key={productFour?.id}>
                <ProductPageLastVisitProduct productModel={productFour} />
              </GridItem>
              :
              null
            }
          </GridContainer>
        </div>
        :
        <></>

      }
    </>

  );
}
