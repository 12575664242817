/*eslint-disable*/
import React from "react";
// I18N
import { useTranslation } from 'react-i18next';
// Api
import api from 'api';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Remove from "@material-ui/icons/Remove";
import Add from "@material-ui/icons/Add";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Footer from "components/Footer/FooterV2.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Table from "components/Table/Table.js";

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import { Link } from "react-router-dom";
import { useAuth } from "store/authContext";

const useStyles = makeStyles(shoppingCartStyle);

export default function ShoppingCartPage() {

  /**Fixed For Each Page */
  React.useEffect(() => {
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  const { authState } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const handleBasketRequest = async () => {
    try {
      setLoading(true);
      const response = await api.get('/basket');
      setData(response.data);
    } catch (error) {
      console.error('Post Error:', error);
      setData({ lineItems: [] });
      setError(error);
      setLoading(false);
    }
  }

  const handleUpdateBasketLineItemRequest = async (id, amount) => {
    try {
      setLoading(true);
      const response = await api.post('/basket/update-basket-line-item', { lineItemId: id, amount: amount });
      setData(response.data);
    } catch (error) {
      console.error('Post Error:', error);
      setData({ lineItems: [] });
      setError(error);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    handleBasketRequest();
  }, []);

  const getLineItems = () => {
    const lineItems = data?.lineItems ? data?.lineItems.map(p =>
      [
        <div className={classes.imgContainer} key={p.productSalesInfoId}>
          <img src={p.productModelImageUrl} alt="..." className={classes.img} />
        </div>,
        <span key={p.productSalesInfoId}>
          <a href={"/" + p.productModelUrl} className={classes.tdNameAnchor}>
            {p.productName}
          </a>
          {p.attributes.map(attribute => <>
            <br />
            <small className={classes.tdNameSmall}>{attribute.attributeName} : {attribute.attributeValue}</small>
          </>)}
        </span>,
        <span key={p.productSalesInfoId}>
          <div className={classes.buttonGroup}>
            <Button
              color="info"
              size="sm"
              round
              className={classes.firstButton}
              onClick={() => handleUpdateBasketLineItemRequest(p.id, p.amount - 1)}
            >
              <Remove />
            </Button>
            &nbsp;&nbsp;
            {p.amount}
            &nbsp;&nbsp;
            <Button
              color="info"
              size="sm"
              round
              className={classes.lastButton}
              onClick={() => handleUpdateBasketLineItemRequest(p.id, p.amount + 1)}
            >
              <Add />
            </Button>
          </div>
        </span>,
        <span key={p.productSalesInfoId}>
          {p.displayPrice.value} <small className={classes.tdNumberSmall}>{p.displayPrice.currency}</small>
        </span>,
        <Button
          link
          className={classes.actionButton}
          onClick={() => handleUpdateBasketLineItemRequest(p.id, 0)}
        >
          <Close />
        </Button>
      ]
    ) : [];

    const shipmentPrice = data?.shipmentPrice?.value ? {
      purchase: true,
      colspan: "3",
      title: t('page.shoppingCard.shippingPrice'),
      amount: (
        <span>
          {data?.shipmentPrice?.value} <small>TL</small>
        </span>
      )
    } : undefined;

    const totalPrice = data?.basketTotal ? {
      purchase: true,
      colspan: "3",
      title: t('page.shoppingCard.total'),
      amount: (
        <span>
          {data?.basketTotal?.value} <small>TL</small>
        </span>
      )
    } : undefined;

    const totalPriceSubmit = data?.basketTotal ? {
      purchase: true,
      colspan: "2",
      amount: (
        <></>
      ),
      col: {
        colspan: 2,
        text: (
          <Link to={authState?.loggedIn ? "/checkout" : ("/giris?callbackurl=" + location)}>
            <Button color="info" round>
              {t('page.shoppingCard.confirmBasket')} <KeyboardArrowRight />
            </Button>
          </Link>
        )
      }
    } : undefined;

    return [
      ...lineItems,
      shipmentPrice,
      totalPrice,
      totalPriceSubmit
    ]
  }

  if (data === null) {
    return (
      <div>
        <Header
          brand={t('page.header.title')}
          links={<HeaderLinks dropdownHoverColor="info" disableBasket={true} />}
          fixed
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
          </div>
        </div>
        <Footer />
      </div >
    );
  }

  return (
    <div>
      <Header
        brand={t('page.header.title')}
        links={<HeaderLinks dropdownHoverColor="info" disableBasket={true} />}
        fixed
        color="gray"
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Card plain>
            <CardBody plain>
              {
                data?.lineItems?.length > 0 ?
                  <>
                    <h3 className={classes.cardTitle}>{t('page.shoppingCard.basketSize', { p: data?.lineItems?.length })}</h3>
                    <Table
                      tableHead={[
                        "",
                        t('page.shoppingCard.product'),
                        t('page.shoppingCard.quantity'),
                        t('page.shoppingCard.price'),
                        ""
                      ]}
                      tableData={getLineItems()}
                      tableShopping
                      customHeadCellClasses={[
                        classes.textCenter,
                        classes.textLeft,
                        classes.textCenter,
                        classes.textRight,
                        classes.textRight
                      ]}
                      customHeadClassesForCells={[0, 1, 2, 3, 4]}
                      customCellClasses={[
                        classes.textLeft,
                        classes.tdName,
                        classes.textCenter,
                        classes.tdNumber + " " + classes.textRight,
                        classes.tdNumber + " " + classes.tdNumberAndButtonGroup
                      ]}
                      customClassesForCells={[0, 1, 2, 3, 4]}
                    />
                  </>
                  :
                  <div className={classes.sectionSections}>
                    <GridContainer justify="center">
                      <GridItem
                        md={8}
                        className={classNames(classes.mrAuto, classes.mlAuto)}
                      >
                        <div className={classes.sectionDescription}>
                          <h2 className={classes.title}>{t('page.shoppingCard.emptyBasketTitle')}</h2>
                          <h5 className={classes.description}>{t('page.shoppingCard.emptyBasketDescription')}</h5>
                          <Link to={"/"}>
                            <Button
                              color="rose"
                              target="_blank"
                              className={classes.navButton}
                              round
                            >
                              {t('page.shoppingCard.emptyBasketDescriptionUrl')}
                            </Button>
                          </Link>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
              }
            </CardBody>
          </Card>
        </div>
      </div>
      <Footer />
    </div >
  );
}
