import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { NotifyProvider } from "store/notifyContext";
import { BasketProvider } from "store/basketContext";
import { AuthProvider } from "store/authContext";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import EcommercePage from "views/EcommercePage/EcommercePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.js";
import SignupPage from "views/SignupPage/SignupPage.js";
import CheckoutPage from "views/CheckoutPage/CheckoutPage.js";
import CheckoutFinsihPage from "views/CheckoutFinsihPage/CheckoutFinsihPage.js";
import LoginRedirect from "views/Login-Redirect";
import OAuth2RedirectHandler from "./views/OAuth2RedirectHandler/OAuth2RedirectHandler";

//var hist = createBrowserHistory();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <AuthProvider>
      <BasketProvider>
        <NotifyProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/login-redirect" element={<LoginRedirect />} />
              <Route path="/sepetim" element={<ShoppingCartPage />} />
              <Route path="/siparislerim" element={<ProfilePage />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="/giris" element={<LoginPage />} />
              <Route path="/uyeol" element={<SignupPage />} />
              <Route path="/hesabim" element={<ProfilePage />} />
              <Route path="/hakkimizda" element={<AboutUsPage />} />
              <Route path="/iletisim" element={<ContactUsPage />} />
              <Route path="/finish" element={<CheckoutFinsihPage />} />
              <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />
              <Route path="/*" element={<EcommercePage />} />
              <Route path="/" element={<PresentationPage />} />
            </Routes>
          </BrowserRouter>
        </NotifyProvider>
      </BasketProvider>
    </AuthProvider>
  </I18nextProvider>,
  document.getElementById("root")
);
