/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
// creditCard
import CreditCard from 'react-credit-cards'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";


import { formatCreditCardNumber, formatCVC, formatExpirationDate } from './utils'

// stlye
import 'react-credit-cards/es/styles-compiled.css'
import styles2 from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/checkoutSections/informationSections.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

export default function SectionInstallment({ handleChange, forwardRefPaymentOption, forwardRefInstallment }) {

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const { t } = useTranslation();

  const classes = useStyles();
  const classes2 = useStyles2();

  const [number, setNumber] = React.useState('');
  const [name, setName] = React.useState('');
  const [expiry, setExpiry] = React.useState('');
  const [cvc, setCvc] = React.useState('');
  const [focused, setFocused] = React.useState('');
  const [selectRadioInstallment, setSelectRadioInstallment] = React.useState();

  forwardRefPaymentOption(() => {
    return data?.paymentOption;
  });

  forwardRefInstallment(() => {
    const installment = {
      data: data?.installmentMap?.find(p => p.installmentCount == selectRadioInstallment),
      cvv: cvc,
      expiryMonth: expiry.split("/")[0],
      expiryYear: expiry.split("/")[1],
      number: number
    };
    return installment;
  });

  React.useEffect(() => {
    handleChange();
  }, [data?.paymentOption, selectRadioInstallment]);

  const handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value)
      setNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value)
      setExpiry(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value)
      setCvc(target.value);
    } else if (target.name === 'name') {
      setName(target.value);
    } else if (target.name === 'installment') {
      setSelectRadioInstallment(target.value);
    }
  }

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  }

  React.useEffect(() => {
    setSelectRadioInstallment(1);
    setData();
    if (number.length > 4) {
      handleRequest();
    }
  }, [number]);

  const handleRequest = async () => {
    if (number.replace(/\s/g, "").length > 7) {
      try {
        setSelectRadioInstallment(1);
        setLoading(true);
        setData();
        const response = await api.post('/payment/installment', { "binCode": number.replace(/\s/g, "").substring(0, 6) });
        console.error('Response:', response.data);
        setData(response.data);
      } catch (error) {
        console.error('Error:', error);
        setData();
        setError(error);
        setLoading(false);
      }
    }
  }

  const getInstallment = () => {
    const installment = data?.installmentMap ? data.installmentMap.map(p =>
      [
        <Radio
          key={p.installmentCount}
          checked={selectRadioInstallment == p.installmentCount}
          onChange={handleInputChange}
          value={p.installmentCount}
          name="installment"
          aria-label={p.installmentCount}
          icon={
            <FiberManualRecord className={classes.radioUnchecked} />
          }
          checkedIcon={
            <FiberManualRecord className={classes.radioChecked} />
          }
          classes={{
            checked: classes.radio,
            root: classes.radioRoot
          }}
        />,
        p.installmentCount === 1 ? "Tek Çekim" : p.installmentCount,
        p.installmentAmount?.value + " " + p.installmentAmount?.currency,
        p.totalAmount?.value + " " + p.totalAmount?.currency,
      ]
    ) : [];

    return [
      ...installment
    ]
  }

  return (
    <>
      <div className={classes.section}>
        <div className={classes.container}>
          <List className={classes2.list}>
            <ListItem className={classes2.inlineBlock}>
              <h4 className={classes.cardTitle}>Ödeme Bilgileri</h4>
            </ListItem>
          </List>

          <GridContainer>
            <GridItem md={6} sm={6}>
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <CustomInput
                    labelText="Adı Soyadı"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      name: "name",
                      onChange: handleInputChange,
                      onFocus: handleInputFocus
                    }}
                  />
                </GridItem>
                <GridItem md={12} sm={12}>
                  <CustomInput
                    labelText="Kart Numarası"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      name: "number",
                      onChange: handleInputChange,
                      onFocus: handleInputFocus
                    }}
                  />
                </GridItem>
                <GridItem md={6} sm={6}>
                  <CustomInput
                    labelText="Ay / Yıl"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      name: "expiry",
                      onChange: handleInputChange,
                      onFocus: handleInputFocus
                    }}
                  />
                </GridItem>
                <GridItem md={6} sm={6}>
                  <CustomInput
                    labelText="CVC / CVV"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      name: "cvc",
                      onChange: handleInputChange,
                      onFocus: handleInputFocus
                    }}
                  />
                </GridItem>
                <GridItem md={6} sm={6}>
                  <label>{data?.message}</label>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem md={6} sm={6}>
              <CreditCard
                number={number}
                name={name}
                expiry={expiry}
                cvc={cvc}
                focused={focused}
                placeholders={{ name: "KART SAHİBİ" }} />
            </GridItem>

            {
              data?.installmentMap ?
                <>
                  <GridItem >
                    <div className={classNames(classes.headerRoot, classes.headerSummary)}>
                      <div className={classNames(classes.headerRootContent, classes.headerSummaryContent)}>
                        <h4 className={classes.title}></h4>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem>
                    <Table
                      striped
                      tableHead={[
                        "",
                        "Taksit Seçenekleri",
                        "Taksit Tutarı",
                        "Toplam Tutar"
                      ]}
                      tableData={getInstallment()}
                      customCellClasses={[
                        classes.textRight,
                        classes.textRight,
                        classes.textRight,
                        classes.textRight
                      ]}
                      customClassesForCells={[0, 1, 2, 3]}
                      customHeadCellClasses={[
                        classes.textRight,
                        classes.textRight,
                        classes.textRight,
                        classes.textRight,
                        classes.textRight
                      ]}
                      customHeadClassesForCells={[0, 1, 2, 3]}
                    />
                  </GridItem>
                </>
                :
                null
            }
          </GridContainer>
        </div>
      </div>
    </>
  );
}
