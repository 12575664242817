/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function FooterLangItem(props) {
  const { children, content, theme, big, className } = props;
  const classes = useStyles();
  const themeType = theme === "transparent" || theme == undefined ? false : true;

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('userLanguage', lng);
  };

  return (
    <>
      {i18n.language.toLowerCase() === 'tr' ?
        <>
          <ListItem className={className.inlineBlock}>
            <span
              className={className.block}
              onClick={() => changeLanguage('en')}
            >
              EN
            </span>
          </ListItem>
          <ListItem className={className.inlineBlock}>
            <span
              className={className.block}
              onClick={() => changeLanguage('az')}
            >
              AZ
            </span>
          </ListItem>
        </>
        :
        null}
      {i18n.language.toLowerCase() === 'en' ?
        <>
          <ListItem className={className.inlineBlock}>
            <span
              className={className.block}
              onClick={() => changeLanguage('tr')}
            >
              TR
            </span>
          </ListItem>
          <ListItem className={className.inlineBlock}>
            <span
              className={className.block}
              onClick={() => changeLanguage('az')}
            >
              AZ
            </span>
          </ListItem>
        </>
        :
        null}
      {i18n.language.toLowerCase() === 'az' ?
        <>
          <ListItem className={className.inlineBlock}>
            <span
              className={className.block}
              onClick={() => changeLanguage('en')}
            >
              EN
            </span>
          </ListItem>
          <ListItem className={className.inlineBlock}>
            <span
              className={className.block}
              onClick={() => changeLanguage('tr')}
            >
              TR
            </span>
          </ListItem>
        </>
        :
        null}
    </>
  );
}

FooterLangItem.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool
};
