/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// image
import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";
import defaultImage from "assets/img/image_placeholder.jpg";
// view
import SectionAddressModal from "views/ProfilePage/Sections/SectionAdressModal";
// style
import styles from "assets/jss/material-kit-pro-react/views/profileSections/productsStyle2.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { toDate } from './date.js';
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionOrderDetail from "./SectionOrderDetail.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionOrder({ order, refreshOrders }) {

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  const [detailShow, setDetailShow] = React.useState(false);

  var handleOrderDetail = orderId => { };

  React.useEffect(() => {
    handleOrderDetail(order.id)
  }, [detailShow]);

  return (

    <div className={classes.ordersPageOrderListItem} >

      <div className={classes.orderRow + " " + (detailShow ? classes.orderRowActive : "")}>
        <div className={classes.ordeRowSummary + " " + (detailShow ? classes.ordeRowSummaryActive : "")} onClick={() => setDetailShow(!detailShow)}>
          <div className={classes.ordeRowSummaryImages}>
            {
              order?.productImages?.map((image, index) =>
                <div className={classes.imageItem} key={index}>
                  <img src={image} alt="" />
                </div>
              )
            }
          </div>
          <div className={classes.ordeRowSummaryOrderData}>
            <div className={classes.ordeRowSummaryOrderData}>
              <small>{t('page.profile.orders.orderNo')}<b >{order.id}</b></small>
            </div>
          </div>

          <div className={classes.ordeRowSummaryProgressBar}>
            <div className={classes.ordeRowSummary}>
              <div className={classes.ordeRowSummarySingleStatus}>
                {order.orderState === "WAITING_FOR_CANCEL" || order.orderState === "CANCELLED" ?
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346633 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842854 10.1217 0 8 0ZM12.1143 6.74286L7.54286 11.3143C7.33185 11.5296 7.04431 11.6528 6.74286 11.6571C6.59165 11.6565 6.44207 11.6259 6.30278 11.567C6.1635 11.5082 6.03728 11.4223 5.93143 11.3143L3.88572 9.22286C3.67051 9.00765 3.54961 8.71577 3.54961 8.41143C3.54961 8.26073 3.57929 8.11151 3.63696 7.97229C3.69463 7.83306 3.77916 7.70656 3.88572 7.6C3.99228 7.49344 4.11878 7.40891 4.258 7.35124C4.39723 7.29358 4.54645 7.26389 4.69715 7.26389C5.00149 7.26389 5.29337 7.38479 5.50857 7.6L6.56 8.66286C6.61742 8.71224 6.69002 8.74048 6.76572 8.74286C6.83806 8.74074 6.90715 8.71229 6.96 8.66286L10.4914 5.13143C10.5977 5.02431 10.7241 4.93929 10.8633 4.88127C11.0026 4.82325 11.152 4.79337 11.3029 4.79337C11.4537 4.79337 11.6031 4.82325 11.7424 4.88127C11.8816 4.93929 12.008 5.02431 12.1143 5.13143C12.3271 5.34556 12.4466 5.63522 12.4466 5.93714C12.4466 6.23907 12.3271 6.52873 12.1143 6.74286Z" fill="red"></path></svg>
                  :
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346633 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842854 10.1217 0 8 0ZM12.1143 6.74286L7.54286 11.3143C7.33185 11.5296 7.04431 11.6528 6.74286 11.6571C6.59165 11.6565 6.44207 11.6259 6.30278 11.567C6.1635 11.5082 6.03728 11.4223 5.93143 11.3143L3.88572 9.22286C3.67051 9.00765 3.54961 8.71577 3.54961 8.41143C3.54961 8.26073 3.57929 8.11151 3.63696 7.97229C3.69463 7.83306 3.77916 7.70656 3.88572 7.6C3.99228 7.49344 4.11878 7.40891 4.258 7.35124C4.39723 7.29358 4.54645 7.26389 4.69715 7.26389C5.00149 7.26389 5.29337 7.38479 5.50857 7.6L6.56 8.66286C6.61742 8.71224 6.69002 8.74048 6.76572 8.74286C6.83806 8.74074 6.90715 8.71229 6.96 8.66286L10.4914 5.13143C10.5977 5.02431 10.7241 4.93929 10.8633 4.88127C11.0026 4.82325 11.152 4.79337 11.3029 4.79337C11.4537 4.79337 11.6031 4.82325 11.7424 4.88127C11.8816 4.93929 12.008 5.02431 12.1143 5.13143C12.3271 5.34556 12.4466 5.63522 12.4466 5.93714C12.4466 6.23907 12.3271 6.52873 12.1143 6.74286Z" fill="#5AD363"></path></svg>
                }
                <span>{t('page.profile.orders.state.' + order.orderState)}</span>
              </div>
            </div>
          </div>

          <div className={classes.ordeRowSummaryPriceAndDate}>
            <span>{toDate(order.createdDate, 'DD MMMM ddd', i18n.language.toLowerCase())} </span>
            <b>{order.paymentTotal.value} <i className="f-s-text t-normal"> {order.paymentTotal.currency} </i></b>
          </div>

          <div className={classes.ordeRowSummaryToggle}>
            <div className={classes.ordeRowSummaryToggleIcon + " " + (detailShow ? classes.ordeRowSummaryToggleIconOpen : "")}>
              <svg viewBox="0 0 24 24" fill="none"><mask id="b6t7mc5gfob26ye8eyzaiw" maskUnits="userSpaceOnUse" x="0" y="5" width="24" height="14"><path fillRule="evenodd" clipRule="evenodd" d="M2.65618 0.434008C2.04415 -0.158311 1.05323 -0.13864 0.447917 0.45805C-0.159636 1.06348 -0.146185 2.02737 0.470336 2.61313L10.2248 11.9045L0.699009 21.41C0.0936976 22.0132 0.107149 22.9793 0.72367 23.5651C1.02857 23.8579 1.42314 24 1.81771 24C2.2235 24 2.62928 23.8448 2.93418 23.541L13.554 12.9426C14.1593 12.3372 14.1459 11.3733 13.5294 10.7854L2.65618 0.434008Z" transform="translate(24 5) rotate(90)" fill="white"></path></mask><g mask="url(#b6t7mc5gfob26ye8eyzaiw)"><path fillRule="evenodd" clipRule="evenodd" d="M0 0H17.5V24H0V0Z" transform="translate(0 19) rotate(-90)" fill="#484848"></path></g></svg>
            </div>
          </div>
        </div>

        {
          detailShow ?
            <SectionOrderDetail forwardRef={c => { handleOrderDetail = c }} refreshOrders={refreshOrders} />
            :
            null
        }
      </div>
    </div>

  );
}
