/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
// @material-ui icons
import Check from "@material-ui/icons/Check";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

// modal for this page
import SectionSelectAddressModal from "./SectionSelectAddressModal";
import SectionNewAddressModal from "./SectionNewAddressModal";

// image
import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";

// style
import styles from "assets/jss/material-kit-pro-react/views/checkoutSections/informationSections/informationSections.js";

const useStyles = makeStyles(styles);

export default function SectionProducts({ handleChange, forwardRefDelivery, forwardRefInvoice, forwardRefDelivery2 }) {

  const { t } = useTranslation();

  const classes = useStyles();

  const [checkedInvoice, setCheckedInvoice] = React.useState(true);

  const [error, setError] = React.useState(null);
  const [dataDelivery, setDataDelivery] = React.useState(null);
  const [dataInvoice, setDataInvoice] = React.useState(null);

  React.useEffect(() => {
    handleChange();
  }, [dataDelivery, dataInvoice]);

  forwardRefDelivery(() => {
    return dataDelivery;
  });

  forwardRefInvoice(() => {
    return dataInvoice;
  });

  React.useEffect(() => {
    handleAdressRequest();
  }, []);

  React.useEffect(() => {
    if (checkedInvoice) {
      setDataInvoice(dataDelivery)
    }
  }, [checkedInvoice]);

  const handleAdressRequest = async () => {
    try {
      const response = await api.get('/address');
      //console.log('Response:', response.data[0]);
      setDataDelivery(response.data[0]);
      setDataInvoice(response.data[0]);
    } catch (error) {
      console.error('Get Error:', error);
      setError(error);
      setDataDelivery();
      setDataInvoice();
    }
  };

  const handleForm = ({ target }) => {
    if (target.name === 'invoice') {
      setCheckedInvoice(!checkedInvoice);
    }
  }

  const hadlerModalSelectAddress = (address, isInvoice) => {
    if (isInvoice) {
      setDataInvoice(address)
    } else {
      if (checkedInvoice) {
        setDataDelivery(address);
        setDataInvoice(address)
      } else {
        setDataDelivery(address);
      }
    }
  }

  var refSelectAddressModal = (isInvoice, show) => {};

  var refNewAddressModal = (isInvoice, show) => {};

  const detechOpenSelectAddressModal = (isInvoice) => {
    refSelectAddressModal(isInvoice, true);
  }

  const detechOpenNewAddressModal = (isInvoice) => {
    refSelectAddressModal(null, false);
    refNewAddressModal(isInvoice, true);
  }

  return (
    <>
      <div className={classes.section}>
        <div className={classes.container}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <h4 className={classes.cardTitle}>Teslimat Bilgileri</h4>
            </ListItem>
          </List>
          {
            dataDelivery ?
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Card
                        raised
                        background
                        className={classes.card}
                        style={{
                          backgroundImage: "url(" + clemOnojeghuo + ")"
                        }}
                      >
                        <CardHeader className={classes.cardHeader}>
                          {
                            checkedInvoice ?
                              <>
                                &nbsp;
                              </>
                              :
                              <>
                                Teslimat Adresi
                              </>
                          }
                        </CardHeader>
                        <CardBody background className={classes.cardBody}>
                          <h4 className={classes.cardTitleWhite + " " + classes.marginAdress}>
                            {dataDelivery.title}
                          </h4>
                          <p className={classes.cardDescription + " " + classes.marginAdress}>
                            {dataDelivery.addressDetail}
                          </p>
                          <p className={classes.cardDescription + " " + classes.marginAdress}>
                            {dataDelivery.deliveryContactPhone}
                          </p>
                          <Button
                            color="danger"
                            className={classes.bottomButton}
                            key="close"
                            aria-label="Close"
                            onClick={() => detechOpenSelectAddressModal(false)}
                          >
                            <Icon>edit</Icon> Ekle / Değiştir
                          </Button>
                        </CardBody>
                      </Card>
                    </GridItem>
                    {
                      checkedInvoice ?
                        null
                        :
                        <GridItem xs={12} sm={12} md={6}>
                          <Card
                            raised
                            background
                            className={classes.card}
                            style={{
                              backgroundImage: "url(" + clemOnojeghuo + ")"
                            }}
                          >
                            <CardHeader className={classes.cardHeader}>
                              Fatura Adresi
                            </CardHeader>
                            <CardBody background className={classes.cardBody}>
                              <h4 className={classes.cardTitleWhite + " " + classes.marginAdress}>
                                {dataInvoice.title}

                              </h4>
                              <p className={classes.cardDescription + " " + classes.marginAdress}>
                                {dataInvoice.addressDetail}
                              </p>
                              <p className={classes.cardDescription + " " + classes.marginAdress}>
                                {dataInvoice.deliveryContactPhone}
                              </p>
                              <Button
                                color="danger"
                                className={classes.bottomButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => detechOpenSelectAddressModal(true)}
                              >
                                <Icon >edit</Icon> Ekle / Değiştir
                              </Button>
                            </CardBody>
                          </Card>
                        </GridItem>
                    }
                  </GridContainer>
                </GridItem>
              </GridContainer>
              :
              null
          }
          {
            dataDelivery ?
              null
              :
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Card
                        raised
                        background
                        className={classes.card}
                      >
                        <CardHeader className={classes.cardHeader}>
                          &nbsp;
                        </CardHeader>
                        <CardBody background className={classes.cardBodyCenter}>
                          <Button
                            color="danger"
                            key="close"
                            aria-label="Close"
                            onClick={() => detechOpenNewAddressModal(false)}
                          >
                            <Icon>add</Icon> Yeni Adres Ekle
                          </Button>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
          }
        </div>
      </div>
      {
        dataDelivery ?
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem md={12} sm={12}>
                  <div
                    className={
                      classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={handleForm}
                          checked={checkedInvoice}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          name="invoice"
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Bu adresi fatura bilgilerimde de kullan"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          :
          null
      }

      <SectionSelectAddressModal forwardRef={c => { refSelectAddressModal = c }} selectAddress={hadlerModalSelectAddress} detechNewAddressModal={detechOpenNewAddressModal} />
      <SectionNewAddressModal forwardRef={c => { refNewAddressModal = c }} selectAddress={hadlerModalSelectAddress} />
    </>
  );
}
