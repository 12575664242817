/*eslint-disable*/
import React from "react";
// I18N
import { useTranslation } from 'react-i18next';
//
import { useLocation, useNavigate } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import People from "@material-ui/icons/People";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LockOpen from "@material-ui/icons/LockOpen";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import SectionAdress from "views/ProfilePage/Sections/SectionAdress.js";
import SectionOrders from "views/ProfilePage/Sections/SectionOrders/SectionOrders";
import SectionPassword from "views/ProfilePage/Sections/SectionPassword";

// image
import christian from "assets/img/faces/christian.jpg";

// style
import style from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

import { useNotify } from "store/notifyContext";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
const useStyles = makeStyles(style);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ProfilePage() {

  /**Fixed For Each Page */
  React.useEffect(() => {
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  const { notifyState, closeNotify } = useNotify();

  const location = useLocation();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const getActiveTab = () => {
    if (location?.hash === "#orders") {
      return 0;
    }
    if (location?.hash === "#address") {
      return 1;
    }
    if (location?.hash === "#password") {
      return 2;
    }
    return 0;
  }

  return (
    <div>
      <Snackbar open={notifyState.open} autoHideDuration={3000} onClose={closeNotify} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={closeNotify} severity={notifyState.type}>
          {notifyState.text}
        </Alert>
      </Snackbar>
      <Header
        color="transparent"
        brand={t('page.header.title')}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 245,
          color: "info"
        }}
      />
      <Parallax
        image={require("assets/img/examples/city.jpg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div className={classes.profileAvatar}>&nbsp;</div>
                <div className={classes.name}>
                  <h3 className={classes.title}>&nbsp;</h3>
                  <h6>&nbsp;</h6>
                </div>
              </div>
            </GridItem>
          </GridContainer>

          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              active={getActiveTab()}
              tabs={[
                {
                  tabButton: t('page.profile.ordersTitle'),
                  tabIcon: ShoppingBasket,
                  tabContent: <SectionOrders />
                },
                {
                  tabButton: t('page.profile.addressTitle'),
                  tabIcon: People,
                  tabContent: <SectionAdress />
                },
                {
                  tabButton: t('page.profile.passwordTitle'),
                  tabIcon: LockOpen,
                  tabContent: <SectionPassword />
                }
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
      <Footer />
    </div>
  );
}
