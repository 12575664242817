// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    text-align: center;
    background: white;
    padding: 2em;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 1.5em;
}

.login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75em;
    margin: 0.5em 0;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-btn img {
    height: 32px;
    float: left;
}

.login-btn.google {
    margin-top: 7px;
}

.login-btn.facebook img {
    height: 20px;
    margin-left: 3px;
}

.google {
    background-color: #db4437;
    color: white;
}

.google:hover {
    background-color: #c23321;
}

.facebook {
    background-color: #4267B2;
    color: white;
}

.facebook:hover {
    background-color: #365899;
}
`, "",{"version":3,"sources":["webpack://./src/views/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".login-container {\n    text-align: center;\n    background: white;\n    padding: 2em;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\nh2 {\n    margin-bottom: 1.5em;\n}\n\n.login-btn {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    padding: 0.75em;\n    margin: 0.5em 0;\n    border: none;\n    border-radius: 4px;\n    font-size: 1em;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.login-btn img {\n    height: 32px;\n    float: left;\n}\n\n.login-btn.google {\n    margin-top: 7px;\n}\n\n.login-btn.facebook img {\n    height: 20px;\n    margin-left: 3px;\n}\n\n.google {\n    background-color: #db4437;\n    color: white;\n}\n\n.google:hover {\n    background-color: #c23321;\n}\n\n.facebook {\n    background-color: #4267B2;\n    color: white;\n}\n\n.facebook:hover {\n    background-color: #365899;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
