import React, { Component } from 'react'
//import query_string from 'query-string';

export default function LoginRedirect() {

    return (
        <>
            <p>LoginRedirect Page</p>
        </>
    )
}

