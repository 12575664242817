/*eslint-disable*/
import React from "react";
// I18N
import { useTranslation } from 'react-i18next';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/FooterV2.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.js";

import image from "assets/img/clint-mckoy.jpg";

const useStyles = makeStyles(errorPageStyle);

export default function ErrorPage() {

  /**Fixed For Each Page */
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand={t('page.header.title')}
        links={<HeaderLinks dropdownHoverColor="dark" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h1 className={classes.title}>{" "}</h1>
              <h2 className={classes.subTitle}>{t('page.error.title')}</h2>
              <h3 className={classes.description}>
                <a
                  href="/"
                  className={classes.block}
                >
                  <i
                    className={classes.socialIcons + " " + classes.marginRight5 + " fa fa-home"}
                  />
                  {t('page.error.gotohomepage')}
                </a>
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
