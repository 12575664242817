/*eslint-disable*/
import React from "react";
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Media from "components/Media/Media.js";
// style
import styles2 from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/checkoutSections/informationSections.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

export default function SectionProducts({ lineItems }) {

  const { t } = useTranslation();

  const classes = useStyles();
  const classes2 = useStyles2();

  return (
    <>
      <div className={classes.section}>
        <div className={classes.container}>
          <List className={classes2.list}>
            <ListItem className={classes2.inlineBlock}>
              <h4 className={classes.cardTitle}>Teslimat Seçenekleri</h4>
            </ListItem>
          </List>

          <GridContainer>
            <GridItem md={12} sm={12}>
              <GridContainer>
                {
                  lineItems ?
                    lineItems.map((value, index) =>
                      <React.Fragment key={value.id}>
                        {index > 0 ?
                          <>
                            <GridItem >
                              <div className={classNames(classes.headerRoot, classes.headerSummary)}>
                                <div className={classNames(classes.headerRootContent, classes.headerSummaryContent)}>
                                </div>
                              </div>
                            </GridItem>
                            <p>&nbsp;</p>
                          </>
                          :
                          <p>&nbsp;</p>
                        }
                        <GridItem md={12} sm={12}>
                          <Media
                            avatar={value.productModelImageUrl}
                            title={
                              <span>
                                {value.productName}
                              </span>
                            }
                            body={
                              <span className={classes.color555}>
                                <p>
                                  Standart Teslimat
                                </p>
                                
                              </span>
                            }
                          />
                        </GridItem>
                      </React.Fragment>
                    )
                    :
                    null
                }
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  );
}
