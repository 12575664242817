import { jwtDecode } from "jwt-decode";

export const isTokenAboutToExpire = (thresholdSeconds = 180) => {
  const token = localStorage.getItem('app_ecom_end_usr_token');
  if (!token) {
    //console.log("isTokenAboutToExpire remove");
    localStorage.removeItem("app_ecom_end_usr_token");
    return true;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); 

    if (decodedToken.exp && decodedToken.exp - currentTime <= thresholdSeconds) {
      //console.log("isTokenAboutToExpire remove1");
      localStorage.removeItem("app_ecom_end_usr_token");
      return true; // Token süresi bitmek üzere
    } else {
      //console.log("isTokenAboutToExpire notRemove");
      return false; // Token süresi bitmek üzere değil
    }
  } catch (error) {
    //console.error('Token decode hatası:', error);
    localStorage.removeItem("app_ecom_end_usr_token");
    return true;
  }
};