import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const OAuth2RedirectHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getUrlParameter = (name) => {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  useEffect(() => {
      const token = getUrlParameter('token');
      const error = getUrlParameter('error');

      if (token) {
          localStorage.setItem('app_ecom_end_usr_token', token);
          navigate('/hesabim', { state: { from: location } });
      } else {
          navigate('/giris', { state: { from: location, error: error } });
      }
  }, [location, navigate]);

  return null;
};

export default OAuth2RedirectHandler;
