/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import CancelPresentation from "@material-ui/icons/CancelPresentation";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// image
import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";
import defaultImage from "assets/img/image_placeholder.jpg";
// view
import SectionAddressModal from "views/ProfilePage/Sections/SectionAdressModal";
// style
import styles from "assets/jss/material-kit-pro-react/views/profileSections/productsStyle2.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { toDate } from './date.js';
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

const useStyles = makeStyles(profilePageStyle);
import { useNotify } from "store/notifyContext";

export default function SectionOrderDetail({ forwardRef, refreshOrders }) {

  const { t } = useTranslation();

  const classes = useStyles();

  const { openNotify } = useNotify();
  //console.log("useNotify", useNotify);

  let notificationAlert = React.createRef();

  forwardRef((orderId) => handleOrderDetailRequest(orderId));

  const [orderId, setOrderId] = React.useState(null);
  const [data, setData] = React.useState(null);

  const handleOrderDetailRequest = async (orderId) => {
    setOrderId(orderId);
    try {
      const response = await api.get('/orders/' + orderId);
      console.log('Response:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setData();
    }
  }

  const handleOrderCancelRequest = async () => {
    try {
      const response = await api.post('/orders/cancel', { orderId: orderId, customerCancelReason: "DO_NOT_NEED" });
      console.log('Response:', response.data);
      openNotify("success", "Siparişiniz İptal Edildi");
      refreshOrders();
    } catch (error) {
      console.error('Get Error:', error);
      openNotify("error", "Siparişiniz İptal Edilemedi");
    } 
  }

  const getShowOrderCancel = () => {
    if (data?.orderState === 'WAITING_SELLER_APPROVE') {
      return true;
    }
    return false;
  }

  const getShowOrderRefund = () => {
    if (data?.orderState === 'PREINITIAL') {
      return true;
    }
    return false;
  }

  return (
    <>
      <div className={classes.ordeRowContainer}>
        <div className={classes.orderDetail}>
          <div className={classes.orderDetailCards}>
            <div className={classes.orderDetailCardsItemContainer}>

              <div className={classes.orderDetailCardsItemContainerItem}>
                <div className={classes.orderDetailCardsItemContainerItemCardColumn}>
                  {data?.lineItems.map(lineItem =>
                    <div className={classes.cardProducts} key={lineItem.id}>
                      <div className={classes.productCard + " " + classes.cardProductsItem}>
                        <div className={classes.productCardImage} >
                          <a aria-label="Ürün fotoğrafı" href="#" aria-disabled="false" target="_self">
                            <img src={lineItem.imageUrl} alt="Tefal Light Mug 400 ml Termos 193gr Ultra Hafif" />
                          </a>
                        </div>
                        <div className={classes.productCardContent} >
                          <div className={classes.productCardContentTitle} >
                            <a aria-label="Ürün adı" href="#" aria-disabled="false" target="_self">{lineItem.productTitle}</a>
                          </div>
                          <div className={classes.productCardContentPrice} >
                            <span>{lineItem.totalPrice.value} <i className="f-s-text t-normal">{lineItem.totalPrice.currency}</i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className={classes.orderDetailCardsColumn + " " + classes.orderDetailCardsColumnInformation}>
                  <div className={classes.lineItemContent + " " + classes.lineItemContentDelivered} >
                    <div className={classes.lineItemContentHeader} >
                      <div className={classes.lineItemContentHeaderContent} >
                        <div className={classes.lineItemContentHeaderContentTitle}>
                          <b>{t('page.profile.orders.state.' + data?.orderState)}</b>
                        </div>
                      </div>
                    </div>
                    <div className={classes.lineItemContentButtons} >
                      <div className={classes.actionButtonGroup} >
                        {getShowOrderCancel() ?
                          <button className={classes.actionButton} type="button" onClick={handleOrderCancelRequest} >
                            <CancelPresentation /> &nbsp; Ürünleri İptal Et
                          </button>
                          :
                          null
                        }

                        {
                          getShowOrderRefund() ?
                            <button className={classes.actionButton} type="button" >
                              <CancelPresentation /> &nbsp; Ürünleri Kolay İade Et
                            </button>
                            :
                            null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.orderDetailFooter}>

            <div className={classes.orderInformation} >
              <div className={classes.orderInformationColumn + " " + classes.orderInformationColumnAddress} >
                <h5 className={classes.orderInformationTitle} >Adres Bilgileri</h5>
                <div className={classes.orderInformationContent} >
                  <div className={classes.orderInformationColumn + " " + classes.orderInformationColumnShippingAddress}>

                    <div className="order-information__column__row">
                      <b className="order-information__content__address-title">Teslimat Adresi</b>
                      <div className="address">
                        <address><p><b>{data?.deliveryAddress?.title}</b></p><p >{data?.deliveryAddress?.address}</p>{data?.deliveryAddress?.neighbourhoodName} / {data?.deliveryAddress?.districtName} / {data?.deliveryAddress?.cityName} <br /><b>{data?.deliveryAddress?.contactName} {data?.deliveryAddress?.contactSurname}  - {data?.deliveryAddress?.phone} </b></address>
                      </div>
                    </div>
                  </div>
                  <div className={classes.orderInformationColumn + " " + classes.orderInformationColumnMt25}>
                    <b className="order-information__content__address-title">Fatura Bilgileri</b>
                    <div className="address">
                      <address><p><b>{data?.billingAddress?.title}</b></p><p >{data?.billingAddress?.address}</p>{data?.billingAddress?.neighbourhoodName} / {data?.billingAddress?.districtName} / {data?.billingAddress?.cityName} <br /><b>{data?.billingAddress?.contactName} {data?.billingAddress?.contactSurname}  - {data?.billingAddress?.phone} </b></address>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.orderInformationColumn + " " + classes.orderInformationColumnPayment} >
                <h5 className={classes.orderInformationTitle} >Ödeme Bilgileri</h5>
                <div className={classes.orderInformationContent}>
                  <div className={classes.orderInformationPayments}>
                    <div className={classes.paymentInfoCard} >
                      <div className={classes.paymentInfoCardBank} >
                      </div>
                      <div className={classes.paymentInfoCardInfo} >
                        <div className={classes.paymentInfoCardInfoNumber}>**** **** **** ****
                        </div>
                        <div className={classes.paymentInfoCardInfoDetail}><span className="t-green">Kredi Kartı ile ödendi</span><span className="t-bold"> ({data?.payments[0]?.installmentCount} Taksit)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.orderInformationGroup}>
                    <span className={classes.orderInformationGroupKey} >Kargo</span>
                    <span className={classes.orderInformationGroupValue} >{data?.shipmentPrice?.value} {data?.shipmentPrice?.currency}</span>
                  </div>
                  <div className={classes.orderInformationGroup}  >
                    <span className={classes.orderInformationGroupKey}>Ürünler</span>
                    <span className={classes.orderInformationGroupValue} >{data?.totalPrice?.value} {data?.totalPrice.currency}</span>
                  </div>

                  <div className={classes.orderInformationGroup + " " + classes.orderInformationGroupDivider}>
                  </div>
                  <div className={classes.orderInformationGroup}>
                    <span className={classes.orderInformationGroupKey}>Genel toplam</span>
                    <span className={classes.orderInformationGroupValue + " " + classes.orderInformationGroupValueTotal}>{data?.paymentTotal?.value} {data?.paymentTotal?.currency}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
