/*eslint-disable*/
import React from "react";
import { useLocation } from 'react-router-dom';
// api
import api from 'api';
// views
import CategoryPage from "views/CategoryPage/CategoryPage.js";
import ProductPage from "views/ProductPage/ProductPage.js";
import ErrorPage from "views/ErrorPage/ErrorPage.js";
import LoadingPage from "views/LoadingPage/LoadingPage.js";

export default function EcommercePage() {

  const location = useLocation();
  const [path, setPath] = React.useState(location.pathname.substring(1));
  const [name, setName] = React.useState();
  const [locationUrl, setLocationUrl] = React.useState(location.pathname);

  const [data, setData] = React.useState(-1);

  const handlePageContextRequest = async () => {
    setData(-1);
    try {
      const response = await api.get('/page/context?path=' + path);
      console.log('Post Response:', response.data);
      setData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Not Found Error:', error.response.data);
      } else {
        console.error('Post Error:', error);
      }
      setData({ type: 0 });
    }
  };

  const handlePageContextSearchRequest = async (name) => {
    setData(-1);
    try {
      const response = await api.get('/page/context?name=' + name);
      console.log('Post Response:', response.data);
      setData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Not Found Error:', error.response.data);
      } else {
        console.error('Post Error:', error);
      }
      setData({ type: 0 });
    }
  };

  React.useEffect(() => {
    if (path === 'search') {
      const searchParams = new URLSearchParams(location.search);
      const qParam = searchParams.get('q');
      console.log("path", qParam);
      setName(qParam);
      handlePageContextSearchRequest(qParam)
    } else {
      handlePageContextRequest();
      setName(undefined);
    }
  }, []);

  React.useEffect(() => {
    if(locationUrl !== location.pathname){
      console.log("location change", location);
      setLocationUrl(location.pathname);
      window.location.reload();
    }
  }, [location]);

  if (data?.type === -1) {
    return <LoadingPage />
  } else if (data?.type === 0) {
    return <ErrorPage />
  } else if (data?.type === 1) {
    return <CategoryPage path={path} name={name} productModels={data.searchResponse.products} totalItems={data.searchResponse.totalItems} />
  } else if (data?.type === 2) {
    return <ProductPage productModel={data.searchResponse.products[0]} />
  } else {
    return <LoadingPage />
  }

}