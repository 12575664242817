/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
import { useLocation } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui icons
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import styles from "assets/jss/material-kit-pro-react/views/checkoutSections/paymentSections.js";

const useStyles = makeStyles(styles);

export default function SectionPayment({ shipmentPrice, totalPrice, basketTotal, deliveryAddress, invoiceAddress, paymentOption, installment }) {

  const { t } = useTranslation();

  const classes = useStyles();

  const location = useLocation();

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [checkedAgreement, setCheckedAgreement] = React.useState(false);

  React.useEffect(() => {
    setError(decodeURIComponent(location.hash).replaceAll("#","").replaceAll("+", " "));
  }, [location.hash]);

  const validForm = () => {
    if (!deliveryAddress?.id) {
      return false;
    }

    if (!invoiceAddress?.id) {
      return false;
    }

    if (!paymentOption?.id) {
      //return false;
    }

    if (!installment?.data?.installmentCount) {
      return false;
    }

    if (!installment?.number) {
      return false;
    }

    if (!installment?.cvv) {
      return false;
    }

    if (!installment?.expiryMonth) {
      return false;
    }

    if (!installment?.expiryYear) {
      return false;
    }

    if (!checkedAgreement) {
      return false;
    }

    return true;
  }

  const handleAdressRequest = async () => {

    if (!validForm()) {
      console.log("Not Valid");
      return;
    }

    try {
      setLoading(true);
      const response = await api.post('/checkout', {
        billingAddressId: invoiceAddress.id,
        deliveryAddressId: deliveryAddress.id,
        installmentCount: installment.data?.installmentCount,
        cardNumber: installment.number,
        cvv: installment.cvv,
        expiryMonth: installment.expiryMonth,
        expiryYear: installment.expiryYear
      });
      console.log('Response:', response.data);
      if (response.data.form3d) {
        window.location.replace(response.data.form3d);
      } else {
        console.log("" , response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Get Error:', error);
      setError(error);
      setData();
    }
  }

  const handleForm = ({ target }) => {
    if (target.name === 'agreement') {
      setCheckedAgreement(!checkedAgreement);
    }
  }

  return (
    <>
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card pricing plain>
                <CardBody plain>
                  <h1 className={classes.cardTitle}>
                    <small>Sipariş Özeti</small>
                  </h1>
                  <ul>
                    {
                      shipmentPrice ?
                        <li>
                          <GridContainer spacing={2}>
                            <GridItem xs={8} md={4} className={classNames(classes.mrAuto, classes.textLeft)}>
                              <p className={classes.description}>Kargo</p>
                            </GridItem>
                            <GridItem xs={4} md={8} className={classNames(classes.mlAuto, classes.textRight)}>
                              <p className={classes.description}>{shipmentPrice?.value} {shipmentPrice?.currency}</p>
                            </GridItem>
                          </GridContainer>
                        </li>
                        :
                        null
                    }
                    {
                      totalPrice ?
                        <li>
                          <GridContainer spacing={2}>
                            <GridItem xs={8} md={4} className={classNames(classes.mrAuto, classes.textLeft)}>
                              <p className={classes.description}>Ürünler</p>
                            </GridItem>
                            <GridItem xs={4} md={8} className={classNames(classes.mlAuto, classes.textRight)}>
                              <p className={classes.description}>{totalPrice?.value} {totalPrice?.currency}</p>
                            </GridItem>
                          </GridContainer>
                        </li>
                        :
                        null
                    }
                    {
                      basketTotal ?
                        <li>
                          <GridContainer spacing={2}>
                            <GridItem xs={8} md={4} className={classNames(classes.mrAuto, classes.textLeft)}>
                              <p className={classes.description} >Toplam</p>
                            </GridItem>
                            <GridItem xs={4} md={8} className={classNames(classes.mlAuto, classes.textRight)}>
                              <p className={classes.description}>{basketTotal?.value} {basketTotal?.currency}</p>
                            </GridItem>
                          </GridContainer>
                        </li>
                        :
                        null
                    }
                  </ul>
                  <GridContainer spacing={2}>
                    <GridItem xs={8} md={1} className={classNames(classes.mrAuto, classes.textLeft, classNames.checkAggrementChecked)}>
                      <Checkbox
                        tabIndex={-1}
                        onClick={handleForm}
                        checked={checkedAgreement}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        name="agreement"
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        className={classes.checkAggrementChecked}
                      />
                    </GridItem>
                    <GridItem xs={4} md={10} className={classNames(classes.mlAuto, classes.textLeft)}>
                      <p className={classes.description + " " + classes.checkAggrement}>Ön bilgilendirme formunu ve Mesafeli satış sözleşmesi'ni onaylıyorum.</p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer spacing={3}>
                    <GridItem >
                      <div className={classNames(classes.headerRoot, classes.headerSummary)}>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <h3 className={classes.cardTitle}>
                    <small>Ödenecek Tutar</small>
                  </h3>
                  <h3 className={classes.cardTitle}>
                    {installment?.data ?
                      <small>{installment.data?.totalAmount?.value} {installment.data?.totalAmount?.currency}</small>
                      :
                      <small>{basketTotal?.value} {basketTotal?.currency}</small>
                    }
                  </h3>
                </CardBody>
                <CardFooter pricing className={classes.justifyContentCenter}>
                  <Button color="rose" round onClick={() => handleAdressRequest()} disabled={!validForm() || loading}>
                    {loading ?
                      (
                        <>
                          <i className="fas fa-spinner fa-spin" /> &nbsp;  &nbsp;
                        </>
                      )
                      : null
                    }
                    Ödeme Yap
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  );
}
