/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// image
import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";

// style
import styles from "assets/jss/material-kit-pro-react/views/checkoutSections/informationSections/informationSectionsSelectAdressModal.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SectionAddressModal({ forwardRef, selectAddress, detechNewAddressModal }) {

  const { t } = useTranslation();

  const classes = useStyles();

  const [signupModal, setSignupModal] = React.useState(false);
  const [isInvoice, setInvoice] = React.useState(false);

  forwardRef((isInvoice, show) => showAddressModal(isInvoice, show));

  const showAddressModal = (isInvoice, show) => {
    console.log("showAddressModal", isInvoice, show)
    if (show) {
      handleAdressRequest();
      setSignupModal(true);
      setInvoice(isInvoice);
    } else {
      setSignupModal(false);
    }
  }

  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  const handleAdressRequest = async () => {
    try {
      const response = await api.get('/address');
      //console.log('Response:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setError(error);
      setData();
    }
  };

  const hadleSelectAddress = (address) => {
    selectAddress(address, isInvoice);
    setSignupModal(false);
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={signupModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setSignupModal(false)}
      aria-labelledby="signup-modal-slide-title"
      aria-describedby="signup-modal-slide-description"
    >
      <Card plain className={classes.modalAddress}>
        <DialogTitle
          id="signup-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setSignupModal(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h3
            className={classes.cardTitle + " " + classes.modalTitle}
          >
            Adres Bilgilerim
          </h3>
        </DialogTitle>
        <DialogContent
          id="signup-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} className={classes.gridItem}>
              <GridContainer className={classes.collections} justifyContent="center">
                {
                  data?.map(p =>
                    <GridItem xs={12} sm={12} md={12} key={p.id}>
                      <Card
                        raised
                        background
                        style={{
                          backgroundImage: "url(" + clemOnojeghuo + ")"
                        }}

                      >
                        <CardHeader className={classes.cardHeader}>
                          <Button
                            color="danger"
                            key="close"
                            aria-label="Close"
                            className={classes.bottomButton}
                            onClick={() => hadleSelectAddress(p)}
                          >
                            Seç
                          </Button>
                        </CardHeader>
                        <CardBody background className={classes.cardBody} onClick={() => hadleSelectAddress(p)}>
                          <h4 className={classes.cardTitleWhite + " " + classes.marginAdress}>
                            {p?.title}
                          </h4>
                          <p className={classes.cardDescription + " " + classes.marginAdress}>
                            {p?.addressDetail}
                          </p>
                          <p className={classes.cardDescription + " " + classes.marginAdress}>
                            {p?.deliveryContactPhone}
                          </p>

                        </CardBody>
                      </Card>
                    </GridItem>
                  )
                }
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    color="danger"
                    key="close"
                    aria-label="Close"
                    className={classes.bottomButton}
                    //onClick={() => setSignupModal(false)}
                    onClick={() => detechNewAddressModal(isInvoice)}

                  >
                    <Icon>add</Icon> Yeni Adres Ekle
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Card>
    </Dialog>
  );
}
