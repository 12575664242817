import {
  container,
  cardTitle,
  title,
  mlAuto,
  mrAuto,
  main,
  whiteColor,
  mainRaised,
  grayColor
} from "assets/jss/material-kit-pro-react.js";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";

import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const profilePageStyle = {
  container,
  ...imagesStyle,
  ...tooltipsStyle,
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + "  !important"
  },
  cardTitle,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  profileAvatar: {
    height: "150px",
    fontWeight: "bold",
    lineHeight: 1,
    background: "#eee",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#3a3a3a",
    margin: "0 auto",
    maxWidth: "160px",
    transform: "translate3d(0, -50%, 0)",
    boxShadow: "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    borderRadius: "50% !important",
    fontSize: "59px"
  },
  description: {
    margin: "1.071rem auto 0",
    //maxWidth: "600px",
    color: grayColor[0] + " !important",
    fontSize: "14px !important"
  },
  textCenter: {
    textAlign: "center !important"
  },
  name: {
    marginTop: "-80px"
  },

  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  title: {
    ...title,
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  follow: {
    position: "absolute",
    top: "0",
    right: "0"
  },
  followIcon: {
    width: "20px",
    height: "20px"
  },
  followButton: {
    marginTop: "-28px !important"
  },
  gridItem: {
    ...mlAuto,
    ...mrAuto
  },
  collections: {
    marginTop: "20px"
  },
  cardBody: {
    display: "flex",
    boxOrient: "vertical",
    boxDirection: "normal",
    flexDirection: "column",
    boxPack: "center",
    justifyContent: "center"
  },
  cardBodyAdress: {
    backdropFilter: "blur(8px)"
  },
  badge: {
    display: "inline-table",
    margin: "0 auto"
  },
  listUnstyled: {
    paddingLeft: "0",
    listStyle: "none",
    "& > li": {
      padding: "5px 0px",
      fontSize: "1em"
    }
  },
  profileTabs: {
    marginTop: "4.284rem",
    marginBottom: "50px"
  },
  card: {
    textAlign: "left !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  parallax: {
    height: "380px",
    backgroundPosition: "top center"
  },
  ordersPageGroups: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  ordersPageGroupsItem: {

  },
  orderRow: {
    marginTop: "16px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffff",
    transition: "all .5s ease",
    borderRadius: "8px",
    boxShadow: "0 1px 2px rgba(72,72,72,.08)",
    border: "solid 1px #e5e5e5"
  },
  ordeRowSummary2: {
    flexDirection: "column",
    alignItems: "center",

  },
  ordeRowSummary: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "16px 24px",
    alignItems: "center",
    borderRadius: "8px !important"

  },
  ordeRowSummaryImages: {
    display: "flex",
    flexDirection: "row",
    marginRight: "16px",
    width: "140px"

  },
  ordeRowSummaryImages: {
    display: "flex",
    flex: "1",
    marginRight: "20px",
    width: "210px"

  },

  imageItem: {
    //backgroundColor: "#e5e5e5",
    //border: "1px solid #DADADA",
    color: "#484848",
    display: "block",
    fontSize: "12px",
    fontWeight: "600",
    height: "40px",
    lineHeight: "40px",
    width: "40px",
    minWidth: "40px",
    minHeight: "40px",
    borderRadius: "2px",
    textAlign: "center",
    boxShadow: "0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    marginRight: "10px",
    "& img": {
      display: "block",
      width: "100%",
      borderRadius: "2px",
      border: "0"
    }
  },
  ordeRowSummaryOrderData: {
    display: "flex",
    maxWidth: "210px",
    flex: "1",
    marginRight: "20px",
    "& small": {
      color: "#919191",
      fontSize: "12px",
      marginTop: "8px",
      lineHeight: "1.5",
      letterSpacing: "-.24px !important",
      textDecoration: "none",
      "& b": {
        fontSize: "14px",
        marginLeft: "4px",
        lineHeight: "1.5",
        letterSpacing: "-.28px",
      }
    }
  },
  ordeRowSummaryOrderNumber: {

  },
  ordeRowSummaryProgressBar: {
    minWidth: "260px",
    flex: "1",
    marginRight: "20px",

  },
  ordeRowSummarySingleStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "8px",
    "& span": {
      fontSize: "12px !important",
      display: "block",
      fontWeight: "600",
      color: "#484848"
    },
    "& svg": {
      marginRight: "6px"
    }



  },
  ordeRowSummaryPriceAndDate: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    fontSize: "12px !important",
    lineHeight: "16px",
    marginRight: "8px",
    flex: "1",
    textAlign: "right",
    "& span": {
      fontWeight: "600",
      color: "#666"
    },
    "& b": {
      color: "#439e4a",
      "& i": {
        fontStyle: "normal",
        fontSize: "13px !important",

      }
    }
  },
  ordeRowSummaryToggle: {
    marginLeft: "40px",
    alignItems: "center",
    color: "#484848",
    display: "flex",
    justifyContent: "flex-end",
    width: "2px",
    textAlign: "right",
    userSelect: "none"
  },
  ordeRowSummaryToggleIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    borderRadius: "32px",
    alignItems: "center",
    justifyContent: "center",
    transition: "all .5s ease",
    "& svg": {
      fill: "currentColor",
      height: "14px",
      width: "14px"
    },
  },

  ordeRowSummaryToggleIconOpen: {
    "& svg": {
      transform: "rotate(180deg)"
    },
  },
  marginAdress: {
    margin: "0px",
    marginBottom: "16px"
  },
  marginAdressEdit: {
    margin: "0px"
  },


  ordeRowContainer: {
    "background-color": "#fff",
    "cursor": "default",
    "position": "relative",
    "margin": "0 24px 16px",
    "padding-top": "16px",
    "margin-left": "40px",
    "margin-right": "40px"
  },
  orderDetail: {
    "display": "flex",
    "flex-direction": "column",
    "width": "100%"
  },
  orderDetailCards: {
    "display": "flex",
    "flex-direction": "column",
  },
  orderDetailCardsItemContainer: {
    "display": "flex",
    "flex-direction": "column",
    "border": "1px solid #e5e5e5",
    "border-radius": "8px",
    "overflow": "hidden"
  },
  orderDetailCardsItemContainerMerchantBar: {
    "display": "inline-flex",
    "flex-direction": "row",
    "justify-content": "space-between",
    "align-items": "center",
    "padding": "8px 16px",
    "border-bottom": "1px solid #e5e5e5"
  },
  orderDetailCardsItemContainerMerchantBarMerchant: {
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-start",
    "align-items": "center",
    "min-height": "27px",
    "margin": "6px 0",
    "box-sizing": "border-box",
    "& a": {
      "display": "inline-flex",
      "flex-direction": "row",
      "align-items": "center",
      "font-weight": "600",
      "font-size": "16px",
      "color": "#646464",
      "margin-right": "8px",
      "text-decoration": "none"
    },
  },
  orderDetailCardsItemContainerItem: {
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "space-between",
    "background-color": "#fff",
    "width": "100%",
    "min-height": "118px"
  },
  orderDetailCardsItemContainerItemCardColumn: {
    "flex": "1",
    "display": "flex",
    "flex-direction": "column",
    "box-sizing": "border-box",
    "width": "50%"
  },
  cardProducts: {
    "display": "flex",
    "justify-content": "center",
    "flex-direction": "column",
    "padding": "16px"
  },
  productCard: {
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-start",
    "flex-wrap": "wrap"
  },
  cardProductsItem: {
    "width": "100%"
  },
  productCardImage: {
    "position": "relative",
    "height": "64px",
    "width": "64px",
    "& img": {
      "display": "block",
      "width": "100%"
    },
  },
  productCardContent: {
    "display": "flex",
    "flex-direction": "column",
    "margin-left": "16px",
    "flex": "1"
  },
  productCardContentTitle: {
    "color": "#484848",
    "font-size": "12px",
    "line-height": "1.5",
    "letter-spacing": "-.24px",
    "& a": {
      "color": "#484848",
      "text-decoration": "none"
    }

  },
  productCardContentPrice: {
    "margin-top": "8px",
    //font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
    "color": "#439e4a",
    "font-size": "15px",
    "font-weight": "600",
    "& i": {
      "font-style": "normal"
    }
  },
  orderDetailCardsColumn: {
    "flex": "1",
    "display": "flex",
    "flex-direction": "column",
    "box-sizing": "border-box",
    "width": "50%"
  },
  orderDetailCardsColumnInformation: {
    "display": "flex",
    "flex-direction": "row"
  },
  lineItemContent: {
    "background-color": "#f5f5f5",
    "color": "#7b7b7b",
    "display": "flex",
    "flex": "1",
    "flex-direction": "column",
    "font-size": "13px",
    "position": "relative"
  },
  lineItemContentDelivered: {
    "background-color": "#f7fdf7"
  },
  lineItemContentHeader: {


    "display": "flex",


    "flex-direction": "row",
    "align-items": "center",
    "padding": "24px 24px 0"
  },
  lineItemContentHeaderIcon: {
    "background-color": "#5ad363",
    "width": "48px",
    "height": "48px",
    "border-radius": "50%",
    "margin-right": "12px",
    "display": "flex",
    "align-items": "center",
    "justify-content": "center",
  },
  lineItemContentHeaderContent: {
    "display": "flex",
    "flex-direction": "column",
    "flex": "1"
  },
  lineItemContentHeaderContentTitle: {
    "font-size": "15px",
    "color": "#484848",
  },
  lineItemContentHeaderContentDate: {
    "color": "#439e4a",
    "font-size": "13px",
    "margin-top": "8px",
    "margin-bottom": "4px",
  },
  lineItemContentButtons: {
    "padding": "12px 24px 24px",
    "margin-top": "12px",
    "border-top": "1px solid #DADADA",
  },
  actionButtonGroup: {
    "display": "flex",
    "flex-direction": "row",
    "flex-wrap": "wrap",
    "justify-content": "flex-start",
    "position": "relative",
  },
  actionButton: {
    "flex": "1 0 auto",
    "background-color": "transparent",
    "border": "none",
    "color": "#484848",
    "font-size": "14px",
    "margin-top": "8px",
    "text-align": "left",
    "cursor": "pointer",
    "display": "inline",
    "width": "50%",
    "text-decoration": "none",
    "flex-grow": "0",
    "padding": "12px 10px",
    "box-sizing": "border-box",
    "transition": "all .5s ease",
    "border-radius": "8px",
    "display": "inline-flex",
    "align-items": "center",
  },

  orderInformation: {
    "color": "#484848",
    "display": "flex",
    "flex-direction": "row",
    "flex-wrap": "wrap",
    "font-size": "13px",
    "padding-left": "8px",
    "padding-right": "8px",
    "margin-top": "48px",
    "padding-bottom": "16px",
    "width": "100%",
  },
  orderInformationColumn: {
    "flex": "1",
  },
  orderInformationTitle: {
    //font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif",
    "color": "#484848",
    "font-size": "20px",
    "font-weight": "600",
    "margin-top": "0",
    "margin-bottom": "24px",
  },
  orderInformationContent: {
    "max-width": "360px",
    "width": "100%",
  },
  orderInformationColumnShippingAddress: {
    "display": "flex",
    "flex-direction": "row",
    "align-items": "flex-start",
  },
  orderInformationColumnMt25: {
    "margin-top": "25px !important",
  },
  orderInformationPayments: {
    "margin-bottom": "16px",
  },
  orderInformationGroup: {
    "margin-bottom": "8px",
    "margin-top": "8px",
    "display": "flex",
    "flex-direction": "row",
    "align-items": "center",
    "align-content": "center",
    "padding-left": "8px",
    "padding-right": "8px",
    "position": "relative",
    "flex-wrap": "wrap",
  },
  orderInformationGroupDivider: {
    "height": "1px",
    "margin-top": "20px !important",
    "margin-bottom": "20px !important",
    "background-color": "#e5e5e5",
  },
  orderInformationGroupKey: {
    "color": "#7b7b7b",
    "font-weight": "600",
    "font-size": "12px",
    "flex": "1",
  },
  paymentInfoCardInfoNumber: {
    "color": "#484848",
    "font-size": "12px",
    "font-weight": "600",
    "line-height": "1.5",
    "letter-spacing": "-.24px",
  },
  paymentInfoCardInfoDetail: {
    "margin-top": "8px",
    "color": "#646464",
    "font-size": "12px",
    "line-height": "1.5",
    "letter-spacing": "-.24px",
  },
  orderInformationGroupValueTotal: {
    //font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif",
    "font-size": "16px",
    "color": "#484848",
  },
  paymentInfoCard: {
    "background-color": "#f5f5f5",
    "padding": "16px",
    "border-radius": "8px",
    "display": "flex",
    "flex-direction": "row",
    "align-content": "center",
    "align-items": "center",
  },
  paymentInfoCardBankLogo: {
    "max-width": "90px",
    "width": "100%",
  },
  paymentInfoCardBank: {
    "width": "90px",
    "text-align": "center",
  },
  paymentInfoCardInfo: {
    "flex": "1",
    "margin-left": "16px",
  },
  orderInformationGroupValue: {
    "text-align": "right",
    "color": "#484848",
    "font-weight": "600",
    "font-size": "12px",
    "flex": "1",
  },
  orderInformationColumnAddress: {

  },

  orderInformationColumnPayment: {

  },
  orderInformationColumnOther: {
    "flex": "0 0 100%",
    "margin-top": "30px",
  },


  orderRowActive: {
    "transform": "translateY(15px)",
    "border-color": "#eee",
    "box-shadow": "0 8px 32px rgba(72,72,72,.16)",
    "margin-bottom": "30px",
    "box-sizing": "border-box"
  },
  ordeRowSummaryActive: {
    //"padding-left": "40px !important",
    //"padding-right": "40px !important",
    "position": "sticky",
    "top": "-15px",
    "z-index": "2",
    "border-radius": "4px",
    "background-color": "#fff"
  }
}

export default profilePageStyle;
