import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const storedLanguage = localStorage.getItem('userLanguage');

const resources = {
  en: {
    translation: {
      page: {
        header: {
          title: "EcomCore",
          login: "Login",
          account: "Account",
          basket: "Basket",
          search: {
            text: "Search EcomCore.com.tr",
            loading: "Loading...",
            showAllProduct: "Show All Product",
            notFound: "Not Found Product..."
          }
        },
        footer: {
          fastDeliveryTitle: "Fast Delivery",
          fastDeliveryDesc: "Your products will be delivered within 2-3 business days.",
          easyReturnsTitle: "Easy Returns",
          easyReturnsDesc: "Free and easy returns within 15 days",
          freeShippingTitle: "Free shipping on orders over 1000 TL",
          freeShippingDesc: "Free shipping on online purchases over 1000 TL in the cart.",
          socialMediaTitle: "Social Media",
          aboutUs: "About us",
          contactUs: "Contact us",
          madeWith: 'made with',
          by: 'by',
          forABetterWeb: 'for a better web',
        },
        presentation: {
          latestOffers: "Latest Offers"
        },
        category: {
          homePage: "Home Page",
          product: {
            loadMore: "Load More..."
          }
        },
        product: {
          outOfStock: "Out of stock",
          description: "Description",
          technicalDetails: "Tecnical Details",
          selectAttribute: "Select {{p}}",
          addToCard: "Add to Cart",
          deliveryTitle: "Fast Delivery",
          deliveryDescription: "The products you order will arrive at your door within 2-3 days, allowing you to quickly access and start using the items you need with pleasure.",
          refundTitle: "Easy Returns",
          refundDescription: "You can easily return the products you purchased within 15 days, thus ensuring complete freedom and confidence in your shopping experience.",
          cargoTitle: "Free Shipping",
          cargoDescription: "Free shipping is available for purchases of 1000 TL and above! Take advantage of this limited-time offer to continue your shopping experience.",
          lastVisit: {
            title: "You may also be interested in"
          },
          notify: {
            addToBasketSuccess: "Product Added to Cart",
            addToBasketError: "The product could not be added to the cart"
          }
        },
        shoppingCard: {
          shippingPrice: "Shipping Fee",
          total: "Total",
          confirmBasket: "Confirm Cart",
          basketSize: "My Cart ({{p}} Item)",
          product: "Product",
          quantity: "Quantity",
          price: "Price",
          emptyBasketTitle: "Your cart is currently empty",
          emptyBasketDescription: "You can start reviewing products to fill your cart from EcomCore's world full of opportunities.",
          emptyBasketDescriptionUrl: "Continue shopping",
        },
        checkoutFinish: {
          title: "Your order has been received successfully",
          desc: "Order Number",
          gotoOrders: "Go to My Orders"
        },
        profile: {
          ordersTitle: "ORDERS",
          addressTitle: "ADDRESS INFORMATION",
          passwordTitle: "PASSWORD",
          orders: {
            orderNo: "Order No: ",
            state: {
              undefined: "",
              WAITING_SELLER_APPROVE: "Waiting Seller Approve",
              PREINITIAL: "Initial",
              CANCELLED : "Canceled",
              WAITING_FOR_CANCEL : "Canceled"
            }
          }
        },
        signin: {
          login: "Login",
          email: "Email...",
          password: "Password...",
          register: 'Click Here to Sign Up',
          secureShopping: "Secure Shopping"
        },
        signup: {
          login: 'Login',
          register: 'Register',
          advantageousOffersTitle: 'Advantageous Offers',
          advantageousOffersDesc: 'Sign up now and don\'t miss out on exclusive deals. Experience the privilege of getting your favorite products at more affordable prices!',
          termsAndConditionsTitle: 'Terms And Conditions',
          termsAndConditionsDesc: 'Your personal data is processed within the scope of the Information Text. By clicking on the \'Register\' or \'Social\' Account button, you confirm that you have read and accepted the Membership Agreement and Privacy Policy.',
          email: "Email...",
          password: "Password...",
          agreeText: 'I agree to the',
          termsAndConditions: 'terms and conditions',
          verificationMailTitle: 'Verification Email Sent',
          verificationMailTitleAlternative: 'Your Membership Transaction is Successful',
          verificationMailDesc: 'Please check your inbox or spam folder.',
          verificationMailDescAlternative: 'You can start taking advantage of the benefits by logging in.',
          agreementModal: {
            title: "Terms And Conditions",
            buttonText: "Confirm"
          }
        },
        contact: {
          title: 'Send us a message',
          desc: ' You can contact us with anything related to our Products. We will get in touch with you as soon as possible.',
          contactInfo: 'Name Surname',
          email: 'E-mail',
          phone: 'Phone Number',
          subject: 'Subject',
          message: 'Message',
          send: 'Send',
          success: 'Your message has reached us. You will be contacted',
          error: 'An error occurred while retrieving your message. Please try again later..'
        },
        error: {
          title: "We don't have such a page, but unique opportunities are here!",
          gotohomepage: "Go to the Homepage"
        }
      }
    },
  },
  tr: {
    translation: {
      page: {
        header: {
          title: "EcomCore",
          login: "Giriş Yap",
          account: "Hesabım",
          basket: "Sepetim",
          search: {
            text: "Ara EcomCore.com.tr",
            loading: "Yükleniyor...",
            showAllProduct: "Tüm Ürünleri Göster",
            notFound: "Ürün Bulunamadı..."
          }
        },
        footer: {
          fastDeliveryTitle: "Hızlı Teslimat",
          fastDeliveryDesc: "Ürünleriniz 2-3 iş günü içerisinde teslim.",
          easyReturnsTitle: "Kolay İade",
          easyReturnsDesc: "15 Gün içerisinde ücretsiz kolay iade imkanı.",
          freeShippingTitle: "1000 TL Üstüne Kargo Bedava",
          freeShippingDesc: "Online alışverişleriniz sepette 1000 TL üzeri ücretsiz kargo imkanı.",
          socialMediaTitle: "Sosyal Medya",
          aboutUs: "Hakkımızda",
          contactUs: "İletişim",
          madeWith: 'daha iyi bir web için',
          by: '',
          forABetterWeb: 'tarafından yapıldı',
        },
        presentation: {
          latestOffers: "Son Teklifler"
        },
        category: {
          homePage: "Ana Sayfa",
          product: {
            loadMore: "Hada Fazla Yükle..."
          }
        },
        product: {
          outOfStock: "Stok Yok",
          description: "Açıklama",
          technicalDetails: "Teknik Özellikler",
          selectAttribute: "{{p}} Seçiniz",
          addToCard: "Sepete Ekle",
          deliveryTitle: "Hızlı Teslimat",
          deliveryDescription: "Sipariş verdiğiniz ürünler 2-3 gün içinde kapınıza kadar gelir, ihtiyaç duyduğunuz ürünlere hızlıca ulaşabilir ve keyifle kullanmaya başlayabilirsiniz.",
          refundTitle: "Kolay İade",
          refundDescription: "Satın aldığınız ürünleri 15 gün içinde kolaylıkla iade edebilirsiniz; böylece alışveriş deneyiminizde tam bir özgürlük ve güven sağlamış olursunuz.",
          cargoTitle: "Ücretsiz Kargo",
          cargoDescription: "1000 TL ve üzeri alışverişlerinizde ücretsiz kargo avantajı sizleri bekliyor! Sınırlı süreli  kampanyadan faydalanarak alışverişlerinizi devam edebilirsiniz.",
          lastVisit: {
            title: "Bunlarda İlginiz Çekebilir"
          },
          notify: {
            addToBasketSuccess: "Ürün Sepete Eklendi",
            addToBasketError: "Ürün Sepete Eklenemedi"
          }
        },
        shoppingCard: {
          shippingPrice: "Kargo Ücreti",
          total: "Toplam",
          confirmBasket: "Sepeti Onayla",
          basketSize: "Sepetim ({{p}} Ürün)",
          product: "Ürün",
          quantity: "Adet",
          price: "Fiyat",
          emptyBasketTitle: "Sepetin şu an boş",
          emptyBasketDescription: "Sepetini EcomCore’nın fırsatlarla dolu dünyasından doldurmak için ürünleri incelemeye başlayabilirsin.",
          emptyBasketDescriptionUrl: "alışverişe devamet",
        },
        checkoutFinish: {
          title: "Siparişiniz başarılı bir şekilde alınmıştır",
          desc: "Sipariş Numaranız",
          gotoOrders: "Siparişlerime Git"
        },
        profile: {
          ordersTitle: "SİPARİŞLERİM",
          addressTitle: "ADRES BİLGİLERİM",
          passwordTitle: "ŞİFRE İŞLEMLERİM",
          orders: {
            orderNo: "Sipariş No: ",
            state: {
              undefined: "",
              WAITING_SELLER_APPROVE: "Satıcı Onayı Bekliyor",
              PREINITIAL: "Oluşmayan Sipariş",
              CANCELLED : "İptal Edildi",
              WAITING_FOR_CANCEL : "İptal Edildi"
            }
          }
        },
        signin: {
          login: "Giriş Yap",
          email: "Email...",
          password: "Şifre...",
          register: 'Üye Olmak İçin Buraya Tıklayın',
          secureShopping: "Güvenli Alışveriş"
        },
        signup: {
          login: 'Giriş Yap',
          register: 'Üye Ol',
          advantageousOffersTitle: 'Avantajlı Teklifler',
          advantageousOffersDesc: 'Hemen üye ol ve sana özel fırsatları kaçırma. En sevdiğin ürünleri daha uygun fiyatlarla alma ayrıcalığını yaşa!',
          termsAndConditionsTitle: 'Koşullar ve Şartlar',
          termsAndConditionsDesc: 'Kişisel verileriniz, Aydınlatma Metni kapsamında işlenmektedir. \'Üye Ol\' veya \'Sosyal Hesap\' butonlarından birine basarak Üyelik Sözleşmesi’ni ve Gizlilik Politikası’nı okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.',
          email: "Email...",
          password: "Şifre...",
          agreeText: 'Onaylıyorum',
          termsAndConditions: 'şartları ve koşulları',
          verificationMailTitle: 'Doğrulama Maili Gönderildi',
          verificationMailTitleAlternative: 'Üyelik İşleminiz Başarılı',
          verificationMailDesc: 'Lütfen gelen kutunuzu veya spam(önemsiz) klasörünü kontrol edin.',
          verificationMailDescAlternative: 'Giriş yaparak avantajlardan faydalanmaya başlayabilirsiniz.',
          agreementModal: {
            title: "Koşullar ve Şartlar",
            buttonText: "Onayla"
          }
        },
        contact: {
          title: 'Bizimle İletişime Geçin',
          desc: 'Ürünlerimizle ilgili her konuda bizimle iletişime geçebilirsiniz. En kısa sürede sizinle iletişime geçeceğiz.',
          contactInfo: 'Ad Soyad',
          email: 'E-posta',
          phone: 'Telefon',
          subject: 'Konu',
          message: 'Mesaj',
          send: 'Gönder',
          success: 'Mesajınız tarafımıza ulaştı. Sizinle iletişime geçilecektir.',
          error: 'Mesajınız alınırken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.'
        },
        error: {
          title: "Böyle bir sayfamız yok ama benzersiz fırsatlar burada!",
          gotohomepage: "Anasayfaya Git"
        }
      }
    },
  },
  az: {
    "translation": {
      "page": {
        "header": {
          "title": "EcomCore",
          "login": "Giriş Et",
          "account": "Hesabım",
          "basket": "Səbətim",
          "search": {
            "text": "Axtar EcomCore.com.tr",
            "loading": "Yüklənir...",
            "showAllProduct": "Bütün Məhsulları Göstər",
            "notFound": "Məhsul Tapılmadı..."
          }
        },
        "footer": {
          "fastDeliveryTitle": "Sürətli Çatdırılma",
          "fastDeliveryDesc": "Məhsullarınız 2-3 iş günü ərzində çatdırılır.",
          "easyReturnsTitle": "Asan Qaytarma",
          "easyReturnsDesc": "15 Gün ərzində pulsuz asan qaytarma imkanı.",
          "freeShippingTitle": "1000 TL-dən Yuxarı Pulsuz Çatdırılma",
          "freeShippingDesc": "Online alış-verişlərinizdə səbətdə 1000 TL-dən yuxarı pulsuz çatdırılma imkanı.",
          "socialMediaTitle": "Sosial Media",
          "aboutUs": "Haqqımızda",
          "contactUs": "Əlaqə",
          "madeWith": "daha yaxşı bir veb üçün",
          "by": "",
          "forABetterWeb": "tərəfindən hazırlanıb"
        },
        "presentation": {
          "latestOffers": "Son Təkliflər"
        },
        "category": {
          "homePage": "Ana Səhifə",
          "product": {
            "loadMore": "Daha Çox Yüklə..."
          }
        },
        "product": {
          "outOfStock": "Stokda Yoxdur",
          "description": "Təsvir",
          "technicalDetails": "Texniki Məlumatlar",
          "selectAttribute": "{{p}} Seçin",
          "addToCard": "Səbətə Əlavə Et",
          "deliveryTitle": "Sürətli Çatdırılma",
          "deliveryDescription": "Sifariş verdiyiniz məhsullar 2-3 gün ərzində qapınıza qədər gəlir, ehtiyac duyduğunuz məhsullara tez bir zamanda çatıb istifadəyə başlaya bilərsiniz.",
          "refundTitle": "Asan Qaytarma",
          "refundDescription": "Satın aldığınız məhsulları 15 gün ərzində asanlıqla qaytara bilərsiniz; bu şəkildə alış-veriş təcrübənizdə tam bir azadlıq və təhlükəsizlik təmin etmiş olursunuz.",
          "cargoTitle": "Pulsuz Çatdırılma",
          "cargoDescription": "1000 TL və üzəri alış-verişlərinizdə pulsuz çatdırılma üstünlüyü sizi gözləyir! Məhdud müddətli kampaniyadan faydalanaraq alış-verişlərinizi davam etdirə bilərsiniz.",
          "lastVisit": {
            "title": "Bunlar da Maraqlı Ola Bilər"
          },
          "notify": {
            "addToBasketSuccess": "Məhsul Səbətə Əlavə Edildi",
            "addToBasketError": "Məhsul Səbətə Əlavə Edilə Bilmədi"
          }
        },
        "shoppingCard": {
          "shippingPrice": "Çatdırılma Qiyməti",
          "total": "Ümumi",
          "confirmBasket": "Səbəti Təsdiqlə",
          "basketSize": "Səbətim ({{p}} Məhsul)",
          "product": "Məhsul",
          "quantity": "Ədəd",
          "price": "Qiymət",
          "emptyBasketTitle": "Səbətiniz hal-hazırda boşdur",
          "emptyBasketDescription": "Səbətinizi EcomCore-nin fürsətlərlə dolu dünyasından doldurmaq üçün məhsulları incələməyə başlaya bilərsiniz.",
          "emptyBasketDescriptionUrl": "alış-verişə davam et"
        },
        "checkoutFinish": {
          "title": "Sifarişiniz uğurla alındı",
          "desc": "Sifariş Nömrəniz",
          "gotoOrders": "Sifarişlərimə Get"
        },
        "profile": {
          "ordersTitle": "SİFARİŞLƏRİM",
          "addressTitle": "ÜNVAN MƏLUMATLARIM",
          "passwordTitle": "ŞİFRƏ İŞLƏRİM",
          "orders": {
            "orderNo": "Sifariş No: ",
            "state": {
              "undefined": "",
              "WAITING_SELLER_APPROVE": "Satıcının Təsdiqi Gözlənilir",
              "PREINITIAL": "Sifarişiniz İşlənir",
              "CANCELLED": "Ləğv Edildi",
              "WAITING_FOR_CANCEL": "Ləğv Edildi"
            }
          }
        },
        "signin": {
          "login": "Giriş Et",
          "email": "Email...",
          "password": "Şifrə...",
          "register": "Üzv Olmaq Üçün Buraya Tıklayın",
          "secureShopping": "Təhlükəsiz Alış-veriş"
        },
        "signup": {
          "login": "Giriş Et",
          "register": "Üzv Ol",
          "advantageousOffersTitle": "Avantajlı Təkliflər",
          "advantageousOffersDesc": "Həmən üzv ol və sənə özəl fürsətləri qaçırma. Sevdiyin məhsulları daha uyğun qiymətlərlə alma ayrıcalığını yaşa!",
          "termsAndConditionsTitle": "Şərtlər və Qaydalar",
          "termsAndConditionsDesc": "Şəxsi məlumatlarınız, Məlumatlandırma Mətnində göstərilmiş qaydada işlənir. 'Üzv Ol' və ya 'Sosial Hesab' düymələrindən birinə basaraq Üzvlük Müqaviləsi və Gizlilik Siyasəti oxuduğunuzu və qəbul etdiyinizi təsdiq edirsiniz.",
          "email": "Email...",
          "password": "Şifrə...",
          "agreeText": "Təsdiq Edirəm",
          "termsAndConditions": "şərtlər və qaydalar",
          "verificationMailTitle": "Doğrulama E-poçtu Göndərildi",
          "verificationMailTitleAlternative": "Üzvlük Prosesiniz Uğurlu Oldu",
          "verificationMailDesc": "Zəhmət olmasa giriş qutunuzu və ya spam(qeyri-mühüm) qovluğunu yoxlayın.",
          "verificationMailDescAlternative": "Giriş edərək üstünlüklərdən faydalanmağa başlaya bilərsiniz.",
          "agreementModal": {
            "title": "Şərtlər və Qaydalar",
            "buttonText": "Təsdiqlə"
          }
        },
        "contact": {
          "title": "Bizimlə Əlaqə Saxlayın",
          "desc": "Məhsullarımızla bağlı hər mövzuda bizimlə əlaqə saxlaya bilərsiniz. Ən qısa zamanda sizinlə əlaqə saxlayacağıq.",
          "contactInfo": "Ad Soyad",
          "email": "E-poçt",
          "phone": "Telefon",
          "subject": "Mövzu",
          "message": "Mesaj",
          "send": "Göndər",
          "success": "Mesajınız bizə çatdı. Sizinlə əlaqə saxlanılacaq.",
          "error": "Mesajınız alınarkən bir xəta baş verdi. Zəhmət olmasa sonra təkrar yoxlayın."
        },
        "error": {
          "title": "Belə bir səhifəmiz yoxdur amma bənzərsiz fürsətlər buradadır!",
          "gotohomepage": "Ana səhifəyə Get"
        }
      }
    }
  }  
};

i18n.use(initReactI18next).init({
  resources,
  lng: storedLanguage || 'en', // Kaydedilmiş dil veya varsayılan olarak İngilizce
  keySeparator: '.', // Allow using dots in keys
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;