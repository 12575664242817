import React, { createContext, useReducer, useContext, useState } from "react";
import { isTokenAboutToExpire } from 'utils/jwt-util';


const initialState = {
  loggedIn: !isTokenAboutToExpire()
};

const actionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return { loggedIn: true };
    case actionTypes.LOGOUT:
      return { loggedIn: false };
    default:
      return state;
  }
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const [authState, dispatch] = useReducer(reducer, initialState);

  const loginHandler = () => {
    console.log("loginHandler");
    dispatch({ type: actionTypes.LOGIN, payload: true });
  };

  const logoutHandler = () => {
    localStorage.removeItem("app_ecom_end_usr_token");
    dispatch({ type: actionTypes.LOGOUT, payload: false });
  };

  return (
    <AuthContext.Provider value={{ authState, loginHandler, logoutHandler }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };