/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// image
import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";
import defaultImage from "assets/img/image_placeholder.jpg";
// view
import SectionAddressModal from "views/ProfilePage/Sections/SectionAdressModal";
// style
import styles from "assets/jss/material-kit-pro-react/views/profileSections/productsStyle2.js";

const useStyles = makeStyles(styles);

export default function SectionProducts() {

  const { t } = useTranslation();

  const classes = useStyles();


  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    handleAdressRequest();
  }, []);

  const handleAdressRequest = async () => {
    try {
      const response = await api.get('/address');
      console.log('Response:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setError(error);
      setData();
    }
  };

  var detechModal = address => {};

  return (
    <GridContainer >
      {/* ADDRESSES */}
      <GridItem xs={12} sm={12} md={12} className={classes.gridItem}>
        <GridContainer className={classes.collections} justifyContent="center">
          {
            data?.map(p =>
              <GridItem xs={12} sm={12} md={4} key={p.id}>
                <Card
                  raised
                  background
                  style={{
                    backgroundImage: "url(" + clemOnojeghuo + ")"
                  }}
                >
                  <CardBody background className={classes.cardBodyAdress}>
                    <h4 className={classes.cardTitleWhite + " " + classes.marginAdress}>
                      {p.title}
                    </h4>
                    <p className={classes.cardDescription + " " + classes.marginAdress}>
                      {p.addressDetail}
                    </p>
                    <p className={classes.cardDescription + " " + classes.marginAdress}>
                      {p.deliveryContactPhone} {/* 534*****60 */}
                    </p>
                    <Button round color="danger" onClick={() => detechModal(p)}>
                      <Icon>edit</Icon> Adresi Düzenle
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
            )
          }
          <GridItem xs={12} sm={12} md={4} >
            <Card
              raised
              background
              className={classes.cardImageBlur}
              style={{
                backgroundImage: "url(" + defaultImage + ")"
              }}
            >
              <CardBody background className={classes.cardBodyAdress}>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <Button round color="danger" onClick={() => detechModal()}>
                  <Icon>add</Icon> Yeni Ekle
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </GridItem>
      {/* ADDRESS MODAL */}
      <GridItem xs={12} sm={6} md={6} lg={6}>
        <SectionAddressModal forwardRef={c => { detechModal = c }} handleAdressRequest={handleAdressRequest}/>
      </GridItem>
    </GridContainer>
  );
}
