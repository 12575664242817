import React, { createContext, useReducer, useContext, useState } from "react";

// Başlangıç durumu
const initialState = {
  items: [],
  detech: 0
};

// Eylem türleri
const actionTypes = {
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
};

// Reducer fonksiyonu
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_ITEM:
      return { items: [...state.items, action.payload] };
    case actionTypes.REMOVE_ITEM:
      return { items: state.items.filter(item => item !== action.payload) };
    default:
      return state;
  }
};

// Context oluşturulması
const BasketContext = createContext();

// Provider bileşeni
const BasketProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [detech, setDetech] = useState(0); 

  const detechHandler = (item) => {
    setDetech(prevDetech => prevDetech + 1);
  };

  const removeItem = (item) => {
    dispatch({ type: actionTypes.REMOVE_ITEM, payload: item });
  };

  const handleBasketRequest = async () => {
    /*try {
      const response = await api.get('/basket');
      console.log('Post Response Get:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Post Error:', error);
      setError(error);
    }*/
  }

  return (
    <BasketContext.Provider value={{ state, detechHandler, removeItem, detech, handleBasketRequest }}>
      {children}
    </BasketContext.Provider>
  );
};

// Custom hook oluşturulması
const useBasket = () => {
  const context = useContext(BasketContext);
  if (!context) {
    throw new Error("useBasket must be used within a BasketProvider");
  }
  return context;
};

export { BasketProvider, useBasket };