/*eslint-disable*/
import React, { useCallback, useReducer } from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import LocalShipping from "@material-ui/icons/LocalShipping";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";

// images
import cardProduct1 from "assets/img/examples/card-product1.jpg";
import cardProduct3 from "assets/img/examples/card-product3.jpg";
import cardProduct4 from "assets/img/examples/card-product4.jpg";
import cardProduct2 from "assets/img/examples/card-product2.jpg";
import emptyImage from "assets/img/examples/suit-4.png";

import { useBasket } from "store/basketContext";

const useStyles = makeStyles(productStyle);

export default function ProductPageLastVisitProduct({ productModel }) {

  /**Fixed For Each Page */
  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */


  if (!productModel) {
    return null;
  }

  const product = productModel.products.find(p => p.psi.bestPrice === true);
  const psi = product?.psi;
  const images = productModel.productModelImages.length > 0 ? productModel.productModelImages[0] : emptyImage;


  return (
    <Card product>
      <CardHeader image>
        <a href={"/" + productModel?.url}>
          <img src={images} alt="cardProduct" />
        </a>
      </CardHeader>
      <CardBody>
        <h6
          className={classNames(
            classes.cardCategory,
            classes.textRose
          )}
        >
          Trending
        </h6>
        <h4 className={classes.cardTitle}>{productModel?.name}</h4>

      </CardBody>
      <CardFooter className={classes.justifyContentBetween}>
        <div className={classes.price}>
          <h4 > {psi ? psi.price + " TL" : ""}</h4>
        </div>
        <div className={classes.stats}>
          <Tooltip
            id="tooltip-top"
            title="Save to Wishlist"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button justIcon color="rose" simple>
              <Favorite />
            </Button>
          </Tooltip>
        </div>
      </CardFooter>
    </Card>

  );
}
