/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
// @material-ui/lab components
import Autocomplete from '@material-ui/lab/Autocomplete';
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Phone from "@material-ui/icons/Phone";
import People from "@material-ui/icons/People";
import Home from "@material-ui/icons/Home";
import AccountBalance from "@material-ui/icons/AccountBalance";
import PermIdentity from '@material-ui/icons/PermIdentity';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// style
import styles from "assets/jss/material-kit-pro-react/views/checkoutSections/informationSections/informationSectionsNewAdressModal.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SectionAddressModal({ forwardRef, selectAddress }) {

  const { t } = useTranslation();

  const classes = useStyles();

  const [signupModal, setSignupModal] = React.useState(false);

  const [cities, setCities] = React.useState();
  const [districts, setDistricts] = React.useState();
  const [neighbourhoods, setNeighbourhoods] = React.useState();

  forwardRef((isInvoice, show) => showAddressModal(isInvoice, show));

  const [error, setError] = React.useState(null);
  const [isInvoice, setInvoice] = React.useState(false);

  const [addressId, setAddressId] = React.useState();
  const [title, setTitle] = React.useState("");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [tckn, setTckn] = React.useState("");
  const [selectCity, setSelectCity] = React.useState("");
  const [selectDistrict, setSelectDistrict] = React.useState("");
  const [selectNeighbourhood, setSelectNeighbourhood] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [selectInvoiceType, setSelectInvoiceType] = React.useState("BIREYSEL");
  const [taxNo, setTaxNo] = React.useState("");
  const [taxOffice, setTaxOffice] = React.useState("");
  const [corpName, setCorpName] = React.useState("");

  React.useEffect(() => {
    if (selectCity) {
      handleDistrictRequest(selectCity);
    }
  }, [selectCity]);

  React.useEffect(() => {
    if (selectDistrict) {
      handleNeighbourhoodRequest(selectCity, selectDistrict);
    }
  }, [selectDistrict]);

  const handleForm = ({ target }) => {
    console.log("target", target);
    if (target.name === 'title') {
      setTitle(target.value);
    } else if (target.name === 'name') {
      setName(target.value);
    } else if (target.name === 'surname') {
      setSurname(target.value);
    } else if (target.name === 'phone') {
      setPhone(target.value);
    } else if (target.name === 'city') {
      setSelectCity(target.value);
      setSelectDistrict("");
      setDistricts();
      setNeighbourhoods();
      setSelectNeighbourhood("");
      //handleDistrictRequest(target.value);
    } else if (target.name === 'district') {
      setSelectDistrict(target.value);
      setSelectNeighbourhood("");
      setNeighbourhoods();
      //handleNeighbourhoodRequest(selectCity, target.value);
    } else if (target.name === 'neighbours') {
      setSelectNeighbourhood(target.value);
    } else if (target.name === 'address') {
      setAddress(target.value);
    } else if (target.name === 'invoiceType') {
      setSelectInvoiceType(target.value);
    } else if (target.name === 'taxNo') {
      setTaxNo(target.value);
    } else if (target.name === 'taxOffice') {
      setTaxOffice(target.value);
    } else if (target.name === 'corpName') {
      setCorpName(target.value);
    } else if (target.name === 'tckn') {
      setTckn(target.value);
    }
  }

  const showAddressModal = (isInvoice, show) => {
    console.log("showAddressModal New", isInvoice, show)
    if (show) {
      setAddressId();
      setTitle(""); //.........
      setName("");
      setSurname("");
      setPhone("");
      setTckn("")
      setSelectCity();
      setSelectDistrict();
      setAddress("")
      setSelectInvoiceType("BIREYSEL");
      setTaxNo("");
      setTaxOffice("");
      setCorpName("");
      setTckn("");
      handleCityRequest();
      setSignupModal(true);
      setInvoice(isInvoice);
    } else {
      setSignupModal(false);
    }
  }

  const handleCityRequest = async () => {
    try {
      const response = await api.get('/cities');
      console.log('Get Response:', response.data);
      setCities(response.data);
    } catch (error) {
      console.error('Post Error:', error);
      setError(error);
      setCities();
    }
  };

  const handleDistrictRequest = async (cityId) => {
    try {
      const response = await api.get('/districts/' + cityId);
      console.log('Get Response:', response.data);
      setDistricts(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setError(error);
      setDistricts();
    }
  };

  const handleNeighbourhoodRequest = async (cityId, districtId) => {
    try {
      const response = await api.get('/neighbourhoods/' + cityId + '/' + districtId);
      console.log('Get Response:', response.data);
      setSelectNeighbourhood(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setError(error);
      setSelectNeighbourhood();
    }
  };

  const handleSaveAddressRequest = async () => {
    const cityName = cities?.find(p => p.id == selectCity)?.name;
    const districtName = districts?.find(p => p.id == selectDistrict)?.name;
    const neighbourhoodName = neighbourhoods?.find(p => p.id == selectNeighbourhood)?.name;

    try {
      const response = await api.post('/address', {
        id: addressId,
        title: title,
        deliveryName: name,
        deliverySurname: surname,
        deliveryContactPhone: phone,
        tckn: tckn,
        cityId: selectCity,
        cityName: cityName,
        districtId: selectDistrict,
        districtName : districtName,
        neighbourhoodId: selectNeighbourhood,
        neighbourhoodName : neighbourhoodName,
        addressDetail: address,
        invoiceType : selectInvoiceType,
        taxNo: selectInvoiceType === "BIREYSEL" ? null : taxNo,
        taxOffice: selectInvoiceType === "BIREYSEL" ? null : taxOffice,
        corpName: selectInvoiceType === "BIREYSEL" ? null : corpName,

      });
      console.log('Get Response:', response.data);
      //setSelectNeighbourhood(response.data);
      selectAddress(response.data, isInvoice);
      setSignupModal(false);
    } catch (error) {
      console.error('Get Error:', error);
      setError(error);
      //setSelectNeighbourhood();
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={signupModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setSignupModal(false)}
      aria-labelledby="signup-modal-slide-title"
      aria-describedby="signup-modal-slide-description"
    >
      <Card plain className={classes.modalAddress}>
        <DialogTitle
          id="signup-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setSignupModal(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h3
            className={classes.cardTitle + " " + classes.modalTitle}
          >
            Adres Bilgilerim
          </h3>
        </DialogTitle>
        <DialogContent
          id="signup-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>

            <GridItem md={12} sm={12}>
              <CustomInput
                labelText="Adres Başığı"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <People />
                    </InputAdornment>
                  ),
                  name: "title",
                  value: title,
                  onChange: handleForm
                }}
              />
            </GridItem>

            <GridItem >
              <div className={classNames(classes.headerRoot, classes.headerSummary)}>
                <div className={classNames(classes.headerRootContent, classes.headerSummaryContent)}>
                  <h4 className={classes.title}>Teslim alacak kişinin bilgileri</h4>
                </div>
              </div>
            </GridItem>

            <GridItem md={6} sm={6}>
              <CustomInput
                labelText="Ad"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <People />
                    </InputAdornment>
                  ),
                  name: "name",
                  value: name,
                  onChange: handleForm
                }}
              />
            </GridItem>

            <GridItem md={6} sm={6}>
              <CustomInput
                labelText="Soyad"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <People />
                    </InputAdornment>
                  ),
                  name: "surname",
                  value: surname,
                  onChange: handleForm
                }}
              />
            </GridItem>

            <GridItem md={6} sm={6}>
              <CustomInput
                labelText="Telefon"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Phone />
                    </InputAdornment>
                  ),
                  name: "phone",
                  value: phone,
                  onChange: handleForm
                }}
              />
            </GridItem>

            <GridItem md={6} sm={6}>
              <CustomInput
                labelText="Tckn"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PermIdentity />
                    </InputAdornment>
                  ),
                  name: "tckn",
                  value: tckn,
                  onChange: handleForm
                }}
              />
            </GridItem>

            <GridItem >
              <div className={classNames(classes.headerRoot, classes.headerSummary)}>
                <div className={classNames(classes.headerRootContent, classes.headerSummaryContent)}>
                  <h4 className={classes.title}>Adres bilgileri</h4>
                </div>
              </div>
            </GridItem>

            <GridItem md={4} sm={4} xs={4}>
              <Autocomplete
                options={cities ? cities : []}
                value={{name : cities?.find(p => p.id === selectCity)?.name}}
                onChange={(event, newValue) => {
                  handleForm({ target: { name: 'city', value: newValue?.id } });
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Şehir" />}
              />
            </GridItem>

            <GridItem md={4} sm={4} xs={4}>
              <Autocomplete
                options={districts ? districts : []}
                value={{name : districts?.find(p => p.id === selectDistrict)?.name}}
                onChange={(event, newValue) => {
                  handleForm({ target: { name: 'district', value: newValue?.id } });
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="İlçe" />}
              />
            </GridItem>

            <GridItem md={4} sm={4} xs={4}>
              <Autocomplete
                options={neighbourhoods ? neighbourhoods : []}
                value={{name : neighbourhoods?.find(p => p.id === selectNeighbourhood)?.name}}
                onChange={(event, newValue) => {
                  handleForm({ target: { name: 'neighbours', value: newValue?.id } });
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Mahalle" />}
              />
            </GridItem>

            <GridItem md={12} sm={12}>
              <CustomInput
                labelText="Adres"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 2,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Home />
                    </InputAdornment>
                  ),
                  name: "address",
                  value: address,
                  onChange: handleForm
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Button
                color="white"
                className={selectInvoiceType === "BIREYSEL" ? classNames(classes.bottomButton, classes.invoiceButtonActive) : classNames(classes.bottomButton, classes.invoiceButtonInActive)}
                onClick={(e) => handleForm({ target: { name: "invoiceType", value: "BIREYSEL" } })}
              >
                Bireysel
              </Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <Button
                aria-label="Close"
                color="white"
                className={selectInvoiceType === "KURUMSAL" ? classNames(classes.bottomButton, classes.invoiceButtonActive) : classNames(classes.bottomButton, classes.invoiceButtonInActive)}
                onClick={(e) => handleForm({ target: { name: "invoiceType", value: "KURUMSAL" } })}
              >
                Kurumsal
              </Button>
            </GridItem>

            {selectInvoiceType === "KURUMSAL" ?

              <>
                <GridItem md={6} sm={6}>
                  <CustomInput
                    labelText="VKN*"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountBalance />
                        </InputAdornment>
                      ),
                      name: "taxNo",
                      value: taxNo,
                      onChange: handleForm
                    }}
                  />
                </GridItem>
                <GridItem md={6} sm={6}>
                  <CustomInput
                    labelText="Vergi Dairesi*"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountBalance />
                        </InputAdornment>
                      ),
                      name: "taxOffice",
                      value: taxOffice,
                      onChange: handleForm
                    }}
                  />
                </GridItem>
                <GridItem md={6} sm={6}>
                  <CustomInput
                    labelText="Firma Adı*"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AccountBalance />
                        </InputAdornment>
                      ),
                      name: "corpName",
                      value: corpName,
                      onChange: handleForm
                    }}
                  />
                </GridItem>
              </>
              :

              null
            }

            <GridItem xs={12} sm={12} md={12}>
              <Button
                color="primary"
                key="close"
                aria-label="Close"
                className={classes.bottomButton}
                round
                onClick={() => handleSaveAddressRequest()}

              >
                {addressId ? "Güncelle" : "Kaydet"}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Card>
    </Dialog>

  );
}
