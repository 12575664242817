import axios from 'axios';
import { isTokenAboutToExpire } from 'utils/jwt-util';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true
})

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('app_ecom_end_usr_token');
    if (!isTokenAboutToExpire()) {
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
});

instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default instance;