/* eslint-disable */
import React from "react";
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// @components
import FooterLangItem from "components/Footer/FooterLangItem.js";
// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-pro-react/components/footerStyleV3.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {

  const { t } = useTranslation();

  const {  theme, className, color } = props;
  const classes = useStyles();
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: false,
    [classes.big]: true,
    [classes.colorWhite] : color !== undefined,
    [className]: className !== undefined
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
         <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/hakkimizda"
                      className={classes.block}
                    >
                      {t('page.footer.aboutUs')}
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="/iletisim"
                      className={classes.block}
                    >
                      {t('page.footer.contactUs')}
                    </a>
                  </ListItem>
                  <FooterLangItem className={classes} />
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()}, {t('page.footer.madeWith')}{" "}
                <Favorite className={classes.icon} /> {t('page.footer.by')}{" "}
                <a href="https://fmss.com.tr/tr" target="_blank">
                  Fmss Team
                </a>{" "}
                {t('page.footer.forABetterWeb')}
              </div>
            </div>
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  color: PropTypes.oneOf(["dark", "white"])
};
