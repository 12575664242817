import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// DOM
import { useNavigate } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from '@material-ui/core/Paper';
// @material-ui/lab components
import Autocomplete from '@material-ui/lab/Autocomplete';
// core components
import Media from "components/Media/Media.js";

// image
import emptyImage from "assets/img/examples/suit-4.png";

// style
import styles from "assets/jss/material-kit-pro-react/components/headerSearchProductStyle";

const useStyles = makeStyles(styles);

export default function HeaderSearchProducts({color}) {

  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [optionList, setOptionList] = React.useState([]);
  const [loadingList, setLoadingList] = React.useState([]);
  const [searchText, setSearchText] = React.useState();

  const handlePageContextFilterRequest = async (name) => {
    setLoadingList([...loadingList.filter(p => p.name !== name), {name : name , loading : true}]);
    setSearchText(name);
    try {
      const response = await api.get('/page/context?name=' + name);
      console.log('Post Response:', response.data);
      let productModels = response.data?.searchResponse?.products.slice(0, 4);
      setOptionList([...optionList?.filter(p => p.name !== name), { name: name, values: productModels }]);
      setLoadingList([...loadingList.filter(p => p.name !== name), {name : name , loading : false}]);
    } catch (error) {
      console.error('Post Error:', error);
      setLoadingList([...loadingList.filter(p => p.name !== name), {name : name , loading : false}]);
      setOptionList([...optionList.filter(p => p.name !== name), { name: name, values: [] }]);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setOptionList([]);
    }
  }, [open]);

  const handleInputChange = (event) => {
    if (event.target.value.length > 3) {
      setOpen(true);
      handlePageContextFilterRequest(event.target.value);
    } else {
      setOpen(false);
      setOptionList([]);
    }
  };

  const handleSelectItem = (event) => {
    if (event?.url) {
      navigate("/" + event.url);
    }
  };

  const handleSelectItemLoadMore = (event) => {
    navigate("/search?q=" + searchText, { state: { "searchText": searchText } });
  };

  return (
    <Autocomplete
      style={{ width: "100%" }}
      className={color ? classes.searchBox : undefined}
      open={open}
      onClose={() => {
        setTimeout(function () {
          setOpen(false);
        }, 500);
      }}
      //getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={optionList.find(p => p.name === searchText)?.values ? optionList.find(p => p.name === searchText)?.values : []}
      PaperComponent={
        () => {
          return <Paper id={"1"} style={{ overflowY: "scroll", maxHeight: "250px" }}>
            <List>
              {optionList.find(p => p.name === searchText)?.values?.map(value => <ListItem className={classes.searchItem} key={value.id} onClick={() => handleSelectItem(value)}>
                <Media
                  key={value.id}
                  avatar={value?.productModelImages?.length > 0 ? value?.productModelImages[0] : emptyImage}
                  body={
                    <span style={{ paddingTop: "27px" }}>
                      {value.name}
                    </span>
                  }
                />
              </ListItem>
              )}
              {loadingList.find(p => p.name === searchText)?.loading ?
                <ListItem className={classes.searchItem}>
                  <ListItemText primary={t('page.header.search.loading')} />
                </ListItem>
                :
                optionList.find(p => p.name === searchText)?.values?.length > 0
                  ?
                  <ListItem className={classes.searchItem} onClick={() => handleSelectItemLoadMore()}>
                    <ListItemText primary={t('page.header.search.showAllProduct')} />
                  </ListItem>
                  :
                  <ListItem className={classes.searchItem}>
                    <ListItemText primary={t('page.header.search.notFound')} />
                  </ListItem>
              }
            </List>
          </Paper>
        }
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('page.header.search.text')}
          variant="outlined"
          onChange={handleInputChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingList.find(p => p.name === searchText)?.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
