/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/productsStyle2.js";

const useStyles = makeStyles(styles);

export default function SectionProducts() {

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const [errorDistanceSellingContract, setErrorDistanceSellingContract] = React.useState(null);
  const [dataDistanceSellingContract, setDataDistanceSellingContract] = React.useState(null);
  const [errorPreliminaryInformationForm, setErrorPreliminaryInformationForm] = React.useState(null);
  const [dataPreliminaryInformationForm, setDataPreliminaryInformationForm] = React.useState(null);

  const handleDistanceSellingContractRequest = async () => {
    try {
      const response = await api.get('/agreement/distance-selling-contract?lang=' + i18n.language.toUpperCase());
      console.log('Response:', response.data);
      setDataDistanceSellingContract(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setErrorDistanceSellingContract(error);
      setDataDistanceSellingContract();
    }
  };

  const handlePreliminaryInformationFormRequest = async () => {
    try {
      const response = await api.get('/agreement/preliminary-information-form?lang=' + i18n.language.toUpperCase());
      console.log('Response:', response.data);
      setDataPreliminaryInformationForm(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setErrorPreliminaryInformationForm(error);
      setDataPreliminaryInformationForm();
    }
  };

  React.useEffect(() => {
    handleDistanceSellingContractRequest();
    handlePreliminaryInformationFormRequest();
  }, []);

  return (
    <>
      <div className={classes.section}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} sm={12}>
              <Accordion
                activeColor="rose"
                collapses={[
                  {
                    title: "Ön bilgilendirme formu",
                    content: (
                    
                        <div dangerouslySetInnerHTML={{ __html: dataPreliminaryInformationForm?.content }} />
                      
                    )
                  },
                  {
                    title: "Mesafeli satış sözleşmesi",
                    content: (
                    
                        <div dangerouslySetInnerHTML={{ __html: dataDistanceSellingContract?.content }} />
                 
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </>
  );
}
