import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui icons
import Cached from "@material-ui/icons/Cached";
import Check from "@material-ui/icons/Check";
import Search from "@material-ui/icons/Search";
// core components
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";

import styles from "assets/jss/material-kit-pro-react/views/categorySections/filterSectionStyle.js";

const useStyles = makeStyles(styles);

export default function SectionFilters({ path, name, attributes, properties, brands, firstTotalItems, categoryChild, handleChangeFilter, forwardRefTotalItemsReset }) {

  const classes = useStyles();

  const propertyKeys = [...new Set(properties?.flatMap(property => property.properties.map(t => t.name)))];
  const propertyKeyValuePairs = propertyKeys?.map(p => {
    return {
      key: p,
      values: [...new Set(properties.flatMap(t => t.properties.filter(k => k.name === p).map(k => k.value)))]
    }
  })
  const [totalItems, setTotalItems] = React.useState(firstTotalItems);

  const [selectAttribute, setSelectAttribute] = React.useState([]);
  const [selectProperty, setSelectProperty] = React.useState([]);
  const [selectBrand, setSelectBrand] = React.useState([]);

  const [priceMin, setPriceMin] = React.useState();
  const [priceMax, setPriceMax] = React.useState();

  forwardRefTotalItemsReset((value) => {
    setTotalItems(value);
  });

  const handleChangePrice = ({ target }) => {
    if (target.name === 'priceMin') {
      setPriceMin(target.value);
    } else if (target.name === 'priceMax') {
      setPriceMax(target.value);
    }
  }

  const handleToggleAttribute = (attributeName, value, checked) => {
    if (selectAttribute.find(p => p.attributeName === attributeName)) {
      const updateSelectAttribute = selectAttribute.map(p => {
        if (p.attributeName === attributeName) {
          if (checked) {
            return { attributeName: p.attributeName, values: [...new Set([value, ...p.values])] }
          } else {
            return { attributeName: p.attributeName, values: [...new Set(p.values.filter(t => t !== value))] }
          }
        }
        return { attributeName: p.attributeName, values: p.values }
      });
      setSelectAttribute(updateSelectAttribute.filter(p => p.values?.length > 0));
    } else {
      if (checked) {
        const updateSelectAttribute = [{ attributeName: attributeName, values: [value] }, ...selectAttribute]
        setSelectAttribute(updateSelectAttribute.filter(p => p.values?.length > 0));
      }
    }
  };

  const handleToggleProperty = (key, value, checked) => {
    if (selectProperty.find(p => p.key === key)) {
      const updateSelectProperty = selectProperty.map(p => {
        if (p.key === key) {
          if (checked) {
            return { key: p.key, values: [...new Set([value, ...p.values])] }
          } else {
            return { key: p.key, values: [...new Set(p.values.filter(t => t !== value))] }
          }
        }
        return { key: p.key, values: p.values }
      });
      setSelectProperty(updateSelectProperty.filter(p => p.values?.length > 0));
    } else {
      if (checked) {
        const updateSelectProperty = [{ key: key, values: [value] }, ...selectProperty];
        setSelectProperty(updateSelectProperty.filter(p => p.values?.length > 0));
      }
    }
  };

  const handleToggleBrand = (value, checked) => {
    if (checked) {
      const updateSelectBrand = [...selectBrand, value]
      setSelectBrand(updateSelectBrand);
    } else {
      const updateSelectBrand = selectBrand.filter(p => p !== value);
      setSelectBrand(updateSelectBrand);
    }
  };

  const filterBrand = () => {
    return brands?.length > 1 ? [({
      title: "Marka",
      content: (
        <div className={classes.customExpandPanel}>
          <div
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            }
          >
            {brands?.map(p => <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  tabIndex={-1}
                  onClick={() => handleToggleBrand(p.name, selectBrand.find(brand => brand === p.name) ? false : true)}
                  checked={
                    selectBrand.find(brand => brand === p.name) ? true : false
                  }
                  checkedIcon={
                    <Check className={classes.checkedIcon} />
                  }
                  icon={
                    <Check className={classes.uncheckedIcon} />
                  }
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                />
              }
              classes={{ label: classes.label }}
              label={p.name}
            />
            )}
          </div>
        </div>
      )
    })] : []
  }

  const filterProperties = () => {
    return propertyKeyValuePairs ? propertyKeyValuePairs.map(p => ({
      title: p.key,
      content: (
        <div className={classes.customExpandPanel}>
          <div
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            }
          >
            {
              p.values?.map(t => <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    tabIndex={-1}
                    onClick={() => handleToggleProperty(p.key, t, selectProperty.find(attribute => attribute.key === p.key)?.values.find(attributeValue => attributeValue === t) ? false : true)}
                    checked={
                      selectProperty.find(attribute => attribute.key === p.key)?.values.find(attributeValue => attributeValue === t) ? true : false
                    }
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={
                      <Check className={classes.uncheckedIcon} />
                    }
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{ label: classes.label }}
                label={t}
              />)
            }
          </div>
        </div>
      )
    })) : []
  }

  const filterAttribute = () => {
    return attributes ? attributes.map(p => ({
      title: p.attributeName,
      content: (
        <div className={classes.customExpandPanel}>
          <div
            className={
              classes.checkboxAndRadio +
              " " +
              classes.checkboxAndRadioHorizontal
            }
          >
            {
              p.attributeValues?.map(t => <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    tabIndex={-1}
                    onClick={() => handleToggleAttribute(p.attributeName, t, selectAttribute.find(attribute => attribute.attributeName === p.attributeName)?.values.find(attributeValue => attributeValue.id === t.id) ? false : true)}
                    checked={
                      selectAttribute.find(attribute => attribute.attributeName === p.attributeName)?.values.find(attributeValue => attributeValue.id === t.id) ? true : false
                    }
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    icon={
                      <Check className={classes.uncheckedIcon} />
                    }
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{ label: classes.label }}
                label={t.name}
              />)
            }
          </div>
        </div>
      )
    })) : []
  }

  return (
    <Card plain>
      <CardBody className={classes.cardBodyRefine}>
        {categoryChild ?
          <>
            <h5 className={classes.cardTitle + " " + classes.textLeft}>
              İlgili Kategoriler
            </h5>
            <List>
              {
                categoryChild.filter(p => p.url).map(p => <ListItem className={classes.inlineBlock}>
                  <a
                    href={"/" + p.url}
                    className={classes.childCategory}
                  >
                    {p.name}
                  </a>
                </ListItem>)
              }
            </List>
          </>
          :
          <></>
        }
        {name ?
          <h5 className={classes.cardTitle + " " + classes.textLeft}>
            {name} araması için
          </h5>
          :
          <></>
        }
        <h5 className={classes.cardTitle + " " + classes.textLeft}>
          {totalItems} adet ürün listeleniyor
        </h5>
        <Accordion
          active={[0, 2]}
          activeColor="rose"
          collapses={[
            {
              title: "Fiyat Aralığı",
              content: (
                <CardBody className={classes.cardBodyFilterPrice}>
                  <GridContainer>
                    <GridItem md={6} sm={6}>
                      <CustomInput
                        labelText="En Az"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          name: "priceMin",
                          value: priceMin,
                          onChange: handleChangePrice
                        }}
                      />
                    </GridItem>
                    <GridItem md={6} sm={6}>
                      <CustomInput
                        labelText="En Çok"
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{

                          name: "priceMax",
                          value: priceMax,
                          onChange: handleChangePrice
                        }}
                      />
                    </GridItem>

                  </GridContainer>
                </CardBody>
              )
            },

            ...filterBrand(),
            ...filterAttribute(),
            ...filterProperties()
          ]}
        />
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <Button
              size="sm"
              onClick={() => {
                setPriceMin("");
                setPriceMax("");
                setSelectBrand([]);
                setSelectAttribute([]);
                setSelectProperty([]);
                handleChangeFilter(
                  {
                    priceMin: null,
                    priceMax: null,
                    brands: [],
                    attributes: [],
                    properties: []
                  }
                )
              }
              }
            >
              <Cached /> Temizle
            </Button>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Button
              size="sm"
              onClick={() => handleChangeFilter(
                {
                  priceMin: priceMin,
                  priceMax: priceMax,
                  brands: selectBrand.map(p => ({ value: p })),
                  attributes: selectAttribute?.map(p => ({ key: p.attributeName, values: p.values.map(t => t.name) })),
                  properties: selectProperty
                }
              )}
            >
              <Search /> Filtrele
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}
