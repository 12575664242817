/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// image
import clemOnojeghuo from "assets/img/examples/clem-onojeghuo.jpg";
import defaultImage from "assets/img/image_placeholder.jpg";
// view
import SectionAddressModal from "views/ProfilePage/Sections/SectionAdressModal";
// style
import styles from "assets/jss/material-kit-pro-react/views/profileSections/productsStyle2.js";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import { toDate } from './date.js';
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import { Link } from "react-router-dom";
import SectionOrder from "./SectionOrder.js";

const useStyles = makeStyles(profilePageStyle);

export default function SectionOrders() {

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [openSuccessNotify, setOpenSuccessNotify] = React.useState(false);
  const [openSuccessDataNotify, setOpenSuccessDataNotify] = React.useState();

  React.useEffect(() => {
    handleOrdersRequest();
  }, []);

  const handleSuccessCloseNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessNotify(false);
  };

  const handleOrdersRequest = async () => {
    setData();
    try {
      setLoading(true);
      const response = await api.get('/orders');
      console.log('Response:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setError(error);
      setData();
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <div className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <p className={" " + classes.textCenter}>
            <i className="fas fa-spinner fa-spin fa-3x" />
          </p>
        </GridItem>
      </GridContainer>
    </div>
  }

  return (
    <div className={classes.ordersPageGroups}>
      <div className={classes.ordersPageGroupsItem}>
        <div className={classes.ordersPageOrderList}>
          {
            data?.data?.map(order => <SectionOrder order={order} key={order.id} refreshOrders={handleOrdersRequest} />)
          }
        </div>
      </div>
      <div className={classes.ordersPageGroupsItem}>
      </div>
    </div>
  );
}
