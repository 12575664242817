/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import LocalShipping from "@material-ui/icons/LocalShipping";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import Accordion from "components/Accordion/Accordion.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import styles2 from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";

// images

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


import { useBasket } from "store/basketContext";
import ProductPageLastVisit from "./ProductPageLastVisit";

const useStyles = makeStyles(productStyle);
const useStyles2 = makeStyles(styles2);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ProductPage({ productModel }) {

  /**Fixed For Each Page */
  React.useEffect(() => {
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  const [product, setProduct] = React.useState(productModel.products.find(p => p.psi.bestPrice === true));
  const [psi, setPsi] = React.useState(product?.psi);
  const [selectAttributes, setSelectAttributes] = React.useState(product?.attributes);

  const attributesKey = [...new Set(productModel.products.flatMap(p => p.attributes.map(t => t.name)))];
  const attributesValue = attributesKey.map(p => { return { key: p, values: productModel.products.flatMap(k => k.attributes?.filter(t => t.name === p).map(t => t.value)) } });
  const images = productModel.productModelImages?.map(p => ({
    original: p,
    thumbnail: p
  }));

  const { detechHandler } = useBasket();

  const [loading, setLoading] = React.useState(false);
  const [openSuccessNotify, setOpenSuccessNotify] = React.useState(false);
  const [openErrorNotify, setOpenErrorNotify] = React.useState(false);

  const handleAdd2BasketRequest = async () => {
    setLoading(true);
    try {
      const response = await api.post('/basket', { "psiId": psi.id, "amount": "1" });
      console.log('Post Response:', response.data);
      setLoading(false);
      detechHandler();
      setOpenSuccessNotify(true);
    } catch (error) {
      console.error('Post Error:', error);
      setOpenErrorNotify(true);
      setLoading(false);
    }
  };

  const handleChangeAttribute = (name, value) => {
    let updatedSelectAttributes = selectAttributes.map(p => {
      if (p.name === name) {
        return { ...p, value: value };
      }
      return p;
    });

    setSelectAttributes(updatedSelectAttributes);

    const updateProduct = productModel.products.find(p => {
      for (let index = 0; index < updatedSelectAttributes.length; index++) {
        const element = updatedSelectAttributes[index];
        if (!p.attributes.find(t => t.name === element.name && t.value === element.value)) {
          return false;
        }
      }
      return true;
    });

    setProduct(updateProduct);
    setPsi(updateProduct?.psi);
  }

  const getPrice = (price) => {
    price.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 3 }); // 1.423.132
  }

  const handleSuccessCloseNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessNotify(false);
  };

  const handleErrorCloseNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorNotify(false);
  };

  return (
    <div className={classes.productPage}>
      <Snackbar open={openSuccessNotify} autoHideDuration={3000} onClose={handleSuccessCloseNotify} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleSuccessCloseNotify} severity="success">
          {t('page.product.notify.addToBasketSuccess')}
        </Alert>
      </Snackbar>
      <Snackbar open={openErrorNotify} autoHideDuration={3000} onClose={handleErrorCloseNotify} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleErrorCloseNotify} severity="error">
          {t('page.product.notify.addToBasketError')}
        </Alert>
      </Snackbar>
      <Header
        brand={t('page.header.title')}
        links={<HeaderLinks dropdownHoverColor="rose" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 40,
          color: "rose"
        }}
      />
      <Parallax
        image={require("assets/img/bg6.jpg")}
        filter="rose"
        className={classes.pageHeader}
      >
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>

          <div className={classNames(classes.main, classes.mainRaised)}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/"
                  className={classes.block}
                >
                  Anasayfa
                </a>
              </ListItem>

              {productModel?.categories?.filter(p => p.url !== "latest-offers").find(p => p.level == 0).name ?
                <>
                  <ListItem className={classes.inlineBlock}>
                    <span >{">"}</span>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href={"/" + productModel?.categories?.filter(p => p.url !== "latest-offers").find(p => p.level == 0).url}
                      className={classes.block}
                    >
                      {productModel?.categories?.filter(p => p.url !== "latest-offers").find(p => p.level == 0).name}
                    </a>
                  </ListItem>
                </>
                :
                <></>
              }
            </List>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  startIndex={0}
                  items={images}
                  showThumbnails={true}
                  renderLeftNav={(onClick, disabled) => {
                    return (
                      <button
                        className='image-gallery-left-nav'
                        disabled={disabled}
                        onClick={onClick}
                      />
                    );
                  }}
                  renderRightNav={(onClick, disabled) => {
                    return (
                      <button
                        className='image-gallery-right-nav'
                        disabled={disabled}
                        onClick={onClick}
                      />
                    );
                  }}
                />
              </GridItem>
              <GridItem md={6} sm={6}>
                <h2 className={classes.title}>{productModel?.name}</h2>
                <h3 className={classes.mainPrice}>
                  {psi && psi.listPrice && psi.listPrice !== psi.price ?
                    <>
                      <span className={classNames(classes.price, classes.priceOld)}>
                        &nbsp;
                        {psi.listPrice + " TL"}
                        &nbsp;
                      </span>
                      <span className={classNames(classes.price, classes.priceNew)}>
                        &nbsp;
                        {psi.price + " TL"}
                      </span>
                    </>
                    :
                    <span className={classes.price}> {psi ? psi.price + " TL" : t('page.category.product.outOfStock')}</span>
                  }
                </h3>
                <Accordion
                  active={0}
                  activeColor="rose"
                  collapses={[
                    {
                      title: t('page.product.description'),
                      content: (
                        <div dangerouslySetInnerHTML={{ __html: productModel?.description }} />
                      )
                    },
                    ...(
                      productModel.propertyItems?.length > 0 || productModel?.brand ?
                        [
                          {
                            title: t('page.product.technicalDetails'),
                            content: (<div>
                              <ul>
                                <li>Marka : {productModel?.brand}</li>
                                {productModel?.propertyItems?.map(propertyItem => <li>{propertyItem.name} : {propertyItem.value} </li>)}
                              </ul>
                            </div>)
                          }
                        ]
                        :
                        []
                    )

                  ]}
                />
                {attributesKey ?
                  <GridContainer className={classes.pickSize}>
                    {
                      attributesKey.map(p =>
                        <GridItem md={6} sm={6}>
                          <label>{t('page.product.selectAttribute', { p })}</label>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={selectAttributes.find(t => t.name === p)?.value}
                              onChange={event => handleChangeAttribute(p, event.target.value)}
                            >
                              {
                                attributesValue.find(t => t.key === p).values.map(t =>
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={t}
                                  >
                                    {t}
                                  </MenuItem>
                                )
                              }
                            </Select>
                          </FormControl>
                        </GridItem>
                      )
                    }
                  </GridContainer>
                  :
                  nulll
                }
                <GridContainer className={classes.pullRight}>
                  <Button round color="rose" disabled={!psi || loading} onClick={handleAdd2BasketRequest}>
                    {loading ?
                      (
                        <>
                          <i className="fas fa-spinner fa-spin" /> &nbsp;  &nbsp;
                        </>
                      )
                      :
                      <ShoppingCart />
                    }
                    {t('page.product.addToCard')} &nbsp;
                  </Button>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </div>

          <div className={classNames(classes.features, classes.textCenter)}>
            <GridContainer>
              <GridItem md={4} sm={4}>
                <InfoArea
                  title={t('page.product.deliveryTitle')}
                  description={t('page.product.deliveryDescription')}
                  icon={LocalShipping}
                  iconColor="info"
                  vertical
                />
              </GridItem>
              <GridItem md={4} sm={4}>
                <InfoArea
                  title={t('page.product.refundTitle')}
                  description={t('page.product.refundDescription')}
                  icon={VerifiedUser}
                  iconColor="success"
                  vertical
                />
              </GridItem>
              <GridItem md={4} sm={4}>
                <InfoArea
                  title={t('page.product.cargoTitle')}
                  description={t('page.product.cargoDescription')}
                  icon={LocalShipping}
                  iconColor="rose"
                  vertical
                />
              </GridItem>
            </GridContainer>
          </div>
          <ProductPageLastVisit url={productModel.url} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
