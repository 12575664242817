/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// style
import styles from "assets/jss/material-kit-pro-react/views/checkoutSections/informationSections/informationSectionsSelectAdressModal.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SectionAgreementModal({ forwardRef, detechConfirm}) {

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const [showModal, setShowModal] = React.useState(false);

  forwardRef(() =>  {handleAgreementRequest(); setShowModal(true)});

  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  const handleAgreementRequest = async () => {
    try {
      const response = await api.get('/agreement/signup?lang=' + i18n.language.toUpperCase());
      console.log('Response:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Get Error:', error);
      setError(error);
      setData();
    }
  };

  return (
    <Dialog
      open={showModal}
      TransitionComponent={Transition}
      onClose={() => setShowModal(false)}
    >
      <Card plain className={classes.modalAddress}>
        <DialogTitle
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setShowModal(false)}
          >
            <Close  />
          </Button>
          <h3
            className={classes.cardTitle}
          >
            {t('page.signup.agreementModal.title')}
          </h3>
        </DialogTitle>
        <DialogContent
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer  justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  <Card plain>
                    <CardBody>
                      <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Button
                    color="success"
                    className={classes.bottomButton}
                    onClick={() => {detechConfirm(true); setShowModal(false);}}
                  >
                    <Icon>check</Icon> {t('page.signup.agreementModal.buttonText')}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Card>
    </Dialog>
  );
}
