/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/FooterV2.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import image from "assets/img/bg7.jpg";

import { useAuth } from "store/authContext";

import './LoginPage.css';

import fbLogo from '../../assets/img/login/facebook.png';
import googleLogo from '../../assets/img/login/google.png';
const useStyles = makeStyles(loginPageStyle);


/**
 * 
 * Bu sayfadaki eksiklikler
 * 
 * 
 * üye girişinde alınan hatalar düzenlenecek
 */

export const OAUTH2_REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI + '/oauth2/redirect'

export const GOOGLE_AUTH_URL = process.env.REACT_APP_AUTH_URL + '/auth-api/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = process.env.REACT_APP_AUTH_URL + '/auth-api/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

export default function LoginPage() {

  const classes = useStyles();
  const { t } = useTranslation();

  const { loginHandler } = useAuth();

  const [loading, setLoading] = React.useState(false);
  const [postData, setPostData] = React.useState({
    email: '',
    password: '',
  });
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [callbackUrl, setCallbackUrl] = React.useState('');

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const callbackParam = searchParams.get('callbackurl');
    if (callbackParam) {
      setCallbackUrl(callbackParam);
    }
  }, []);

  const handlePostRequest = async () => {
    setLoading(true);
    try {
      const response = await api.post('/auth/sign-in', postData);
      console.log('Post Response:', response.data);
      setData(response.data);
      setLoading(false);
      localStorage.setItem('app_ecom_end_usr_token', response.data?.accessToken);
      loginHandler();
      window.location.href = callbackUrl || '/';
    } catch (error) {
      console.error('Post Error:', error);
      setError(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPostData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand={t('page.header.title')}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>{t('page.signin.login')}</h4>
                    <div className={classes.socialLine}>
                      {/* 
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-facebook" />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-apple" />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-google-plus-g" />
                      </Button>
                       */}
                    </div>
                  </CardHeader>
                  <CardBody signup>
                    <CustomInput
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: t('page.signin.email'),
                        type: "email",
                        name: "email",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        onChange: handleChange
                      }}
                    />
                    <CustomInput
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: t('page.signin.password'),
                        type: "password",
                        name: "password",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                        onChange: handleChange
                      }}
                    />
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button simple color="primary" size="lg" disabled={loading} onClick={handlePostRequest}>
                      {loading ?
                        (
                          <>
                            <i className="fas fa-spinner fa-spin" /> &nbsp;  &nbsp;
                          </>
                        )
                        : null
                      }
                      {t('page.signin.login')}
                    </Button>
                  </div>
                  <div className={classes.textCenter}>
                    <Link to="/uyeol" >
                      <Button simple color="primary" disabled={loading}>
                        {t('page.signin.register')}
                      </Button>
                    </Link>
                  </div>
                  <div className={classes.textCenter}>
                    <div className="login-container">
                      <a className="login-btn google" href={GOOGLE_AUTH_URL}>
                        <img src={googleLogo} alt="Google"/> &nbsp; Log in with Google
                      </a>
                      <a className="login-btn facebook" href={FACEBOOK_AUTH_URL}>
                        <img src={fbLogo} alt="Facebook"/> &nbsp; Log in with Facebook
                      </a>
                    </div>

                  </div>
                </form>
              </Card>
              <p className={" " + classes.textCenter}>
                <i className="fas fa-unlock-alt"/> &nbsp;  &nbsp;  {t('page.signin.secureShopping')}
              </p>
            </GridItem>
          </GridContainer>
        </div>

        <Footer
            color={'white'}
            className={classes.footer}/>
      </div>
    </div>
  );
}
