import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

import dg12 from "assets/img/dg12.jpg";
import dg13 from "assets/img/dg13.jpg";
import dg14 from "assets/img/dg14.jpg";

const useStyles = makeStyles(headersStyle);

export default function SectionHeaders({ ...rest }) {

  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    className: classes.slider
  };

  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div className="cd-section" {...rest}>
      <div className={classes.sectionBlank} id="blanksection" />
      <div>
        <Carousel {...settings}>
          {/* Carousel 3 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg14}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={7}
                    md={7}
                    className={classNames(classes.mlAuto, classes.textRight)}
                  >
                    <div className={classes.bottom}>
                      <Link to="/macbook-dizustu-bilgisayarlar" className={classes.buttomLink}>
                        <Button color="danger" size="lg">
                          <ShoppingCart /> Acele Et Kaçırma
                        </Button>
                      </Link>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg13}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={7}
                    md={7}
                    className={classNames(classes.mlAuto, classes.textCenter)}
                  >
                    <div className={classes.bottom}>
                      <Link to="/macbook-dizustu-bilgisayarlar" className={classes.buttomLink}>
                        <Button color="danger" size="lg">
                          <ShoppingCart /> Acele Et Kaçırma
                        </Button>
                      </Link>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg12}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={7}
                    md={7}
                    className={classNames(classes.mlAuto, classes.textRight)}
                  >
                    <div className={classes.bottom}>
                      <Link to="/macbook-dizustu-bilgisayarlar" className={classes.buttomLink}>
                        <Button color="danger" size="lg">
                          <ShoppingCart /> Acele Et Kaçırma
                        </Button>
                      </Link>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
      {/* HEADER 3 END */}
    </div>
  );
}
