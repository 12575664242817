/*eslint-disable*/
import React from "react";
// API
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Lock from "@material-ui/icons/LockOpen";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// style
import styles from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(styles);

export default function SectionPassword() {

  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={3}>
            <CustomInput
              labelText="Yeni Şifre"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Lock />
                  </InputAdornment>
                ),
                name: "title",

              }}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={3}>
            <CustomInput
              labelText="Yeni Şifre (Tekrar)"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Lock />
                  </InputAdornment>
                ),
                name: "title",

              }}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={4}>
            <div className={classes.textCenter}>
              <Button  color="rose">
                Güncelle
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
