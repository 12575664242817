/* eslint-disable */
import React from "react";
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @components
import FooterLangItem from "components/Footer/FooterLangItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-pro-react/components/footerStyleV2.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {

  const { t } = useTranslation();

  const { children, content, theme, big, className } = props;
  const classes = useStyles();

  const footerClasses = classNames({
    [classes.footer]: true,
    [classes['dark']]: true,
    [classes.big]: true
    //,
    //[className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  //console.log("className.left", classes.className.)
  //console.log("className.left", className.left)

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div>
          <div className={classes.content}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <h5>
                  <i
                    className="fas fa-shipping-fast"
                    style={{
                      fontSize: "24px"
                    }} /> {" "}
                  {t('page.footer.fastDeliveryTitle')}
                </h5>
                <p>
                  {t('page.footer.fastDeliveryDesc')} {" "}
                </p>
                <h5>
                  <i
                    className="fas fa-undo"
                    style={{
                      fontSize: "24px"
                    }} /> {" "}  {t('page.footer.easyReturnsTitle')}
                </h5>
                <p>
                  {t('page.footer.easyReturnsDesc')} {" "}
                </p>
                <h5>
                  <i
                    className="fas fa-bullhorn"
                    style={{
                      fontSize: "24px"
                    }} /> {" "}
                  {t('page.footer.freeShippingTitle')}
                </h5>
                <p>
                  {t('page.footer.freeShippingDesc')} {" "}
                </p>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <h5>{t('page.footer.socialMediaTitle')}</h5>
                <div className={classes.galleryFeed}>
                  <i
                    className={classNames(
                      classes.img,
                      classes.imgRaised,
                      classes.imgRounded,
                      "fab fa-instagram"
                    )} style={{
                      fontSize: "55px"
                    }} />
                  <i
                    className={classNames(
                      classes.img,
                      classes.imgRaised,
                      classes.imgRounded,
                      "fab fa-whatsapp"
                    )} style={{
                      fontSize: "55px"
                    }} />
                  <i
                    className={classNames(
                      classes.img,
                      classes.imgRaised,
                      classes.imgRounded,
                      "fab fa-facebook-square"
                    )} style={{
                      fontSize: "55px"
                    }} />
                  <i
                    className={classNames(
                      classes.img,
                      classes.imgRaised,
                      classes.imgRounded,
                      "fab fa-twitter"
                    )} style={{
                      fontSize: "55px"
                    }} />
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <hr />
        </div>

        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/hakkimizda"
                  className={classes.block}
                >
                  {t('page.footer.aboutUs')}
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/iletisim"
                  className={classes.block}
                >
                  {t('page.footer.contactUs')}
                </a>
              </ListItem>
              <FooterLangItem className={classes} />
            </List>
          </div>
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()}, {t('page.footer.madeWith')}{" "}
            <a href="https://fmss.com.tr/tr" target="_blank">
              Fmss Team
            </a>{" "}
            {t('page.footer.forABetterWeb')}
          </div>
        </div>

        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool
};
