import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import dg15 from "assets/img/dg15.png";
import dg16 from "assets/img/dg16.jpg";
import dg17 from "assets/img/dg17.png";
import dg19 from "assets/img/dg19.jpg";
import dg20 from "assets/img/dg20.png";
import dg21 from "assets/img/dg21.png";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/blogStyle.js";

const useStyles = makeStyles(styles);

export default function SectionBlog() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 className={classes.sectionTitle}>Popüler Kategoriler</h2>
        <GridContainer>
          <GridItem xl={2} lg={2} md={4} sm={3}>
            <Card blog>
              <CardHeader image>
                <a href="/macbook-dizustu-bilgisayarlar">
                  <img src={dg15} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${dg15})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xl={2} lg={2} md={4} sm={3}>
            <Card blog>
              <CardHeader image>
                <a href="/macbook-dizustu-bilgisayarlar">
                  <img src={dg16} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${dg16})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xl={2} lg={2} md={4} sm={3}>
            <Card blog>
              <CardHeader image>
                <a href="/macbook-dizustu-bilgisayarlar">
                  <img src={dg17} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${dg17})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xl={2} lg={2} md={4} sm={3}>
            <Card blog>
              <CardHeader image>
                <a href="/macbook-dizustu-bilgisayarlar">
                  <img src={dg21} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${dg21})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xl={2} lg={2} md={4} sm={3}>
            <Card blog>
              <CardHeader image>
                <a href="/macbook-dizustu-bilgisayarlar">
                  <img src={dg19} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${dg19})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </GridItem><GridItem xl={2} lg={2} md={4} sm={3}>
            <Card blog>
              <CardHeader image>
                <a href="/macbook-dizustu-bilgisayarlar">
                  <img src={dg20} alt="..." />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{ backgroundImage: `url(${dg20})`, opacity: 1 }}
                />
              </CardHeader>
              <CardBody>
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>
      </div>
    </div>
  );
}
