/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// DOM
import { useNavigate } from 'react-router-dom';
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/FooterV2.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// image
import image from "assets/img/bg7.jpg";

// style
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import SectionAgreementModal from "./Sections/SectionAggrementModal";

const useStyles = makeStyles(signupPageStyle);

/**
 * 
 * Bu sayfadaki eksiklikler
 * 
 * 
 * üye olunca atılan mail düzenlenecek
 * üye olunca alınan hatalar düzenlenecek
 * üye olunca üyelik validasyonu
 * üye olunca sözleşmenin gösterilmesi onaylanması
 */

export default function SignUpPage() {

  /**Fixed For Each Page */
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  /**Navigate */
  const navigate = useNavigate();

  /**Agreement Modal Reference */
  var refAgreementModal = () => { };

  /**State */
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [postData, setPostData] = React.useState({
    email: '',
    password: '',
  });
  const [showMailValidate, setShowMailValidate] = React.useState(false);
  const [error, setError] = React.useState(null);

  /**Agreement Toggle */
  const handleToggle = isChecked => {
    setChecked(isChecked);
  };

  /**SignUp Request */
  const handlePostRequest = async () => {
    if (!validForm()) {
      return;
    }

    setShowMailValidate(false);
    setLoading(true);
    try {
      const response = await api.post('/auth/signup', postData);
      console.log('Post Response:', response.data);
      setShowMailValidate(true);
    } catch (error) {
      console.error('Post Error:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  /**Input Form Change */
  const handleChange = (e) => {
    setShowMailValidate(false);
    const { name, value } = e.target;
    setPostData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  /**Verify Form */
  const validForm = () => {
    // TODO : 
    return true;
  }

  /**Agreement Modal Open */
  const detechOpenAgreementModal = () => {
    refAgreementModal();
  }

  let container = null;
  if (showMailValidate === true) {
    /**SignUp Verification Page */
    setTimeout(function () {
      navigate("/giris");
    }, 3000);

    container = (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={10}>
            <Card className={classes.cardSignup}>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={5} md={5}>
                    <div className={classes.textCenter}>
                      <InfoArea
                        className={classes.infoArea}
                        vertical
                        //title={t('page.signup.verificationMailTitle')}
                        //description={t('page.signup.verificationMailDesc')}
                        title={t('page.signup.verificationMailTitleAlternative')}
                        description={t('page.signup.verificationMailDescAlternative')}
                        icon={VerifiedUser}
                        iconColor="success"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  } else {
    container = (
      /**SignUp Form Page */
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={10} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>{t('page.signup.register')}</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={5} md={5}>
                    <InfoArea
                      className={classes.infoArea}
                      title={t('page.signup.advantageousOffersTitle')}
                      description={t('page.signup.advantageousOffersDesc')}
                      icon={Timeline}
                      iconColor="rose"
                    />
                    <InfoArea
                      className={classes.infoArea}
                      title={t('page.signup.termsAndConditionsTitle')}
                      description={t('page.signup.termsAndConditionsDesc')}
                      icon={Group}
                      iconColor="info"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    {/*
                    <div className={classes.textCenter}>
                      <Button justIcon round color="twitter">
                        <i className={classes.socials + " fab fa-twitter"} />
                      </Button>
                      {` `}
                      <Button justIcon round color="dribbble">
                        <i className={classes.socials + " fab fa-dribbble"} />
                      </Button>
                      {` `}
                      <Button justIcon round color="tumblr">
                        <i className={classes.socials + " fab fa-apple"} />
                      </Button>
                      {` `}
                      <Button justIcon round color="facebook">
                        <i
                          className={classes.socials + " fab fa-facebook-f"}
                        />
                      </Button>
                      {` `}
                    </div>
                    */}
                    <form className={classes.form}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: t('page.signup.email'),
                          name: "email",
                          onChange: handleChange
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className={classes.inputAdornment}
                            >
                              <Icon className={classes.inputAdornmentIcon}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          placeholder: t('page.signup.password'),
                          type: "password",
                          name: "password",
                          onChange: handleChange
                        }}
                      />
                      <FormControlLabel
                        classes={{
                          label: classes.label
                        }}
                        control={
                          <Checkbox
                            onClick={() => handleToggle(!checked)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            checked={checked}
                          />
                        }
                        label={
                          <span>
                            {t('page.signup.agreeText')} <a href="#pablo" onClick={detechOpenAgreementModal}>{t('page.signup.termsAndConditions')}</a>.
                          </span>
                        }
                      />
                      <div className={classes.textCenter}>
                        <Button round color="primary" disabled={loading || !validForm()} onClick={handlePostRequest}>
                          {loading ?
                            (
                              <>
                                <i className="fas fa-spinner fa-spin" /> &nbsp;  &nbsp;
                              </>
                            )
                            : null
                          }
                          {t('page.signup.register')}
                        </Button>
                        <Link to={"/giris?callbackurl=" + location.origin} >
                          <Button round color="primary">
                            {t('page.signup.login')}
                          </Button>
                        </Link>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {/*Agreement Modal */}
        <SectionAgreementModal forwardRef={c => { refAgreementModal = c }} detechConfirm={handleToggle} />
      </div>
    );
  }

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand={t('page.header.title')}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        {container}
        <Footer color={"white"} />
      </div>
    </div>
  );
}
