import {
  section,
  container,
  title,
  description,
  cardTitle,
  main,
  mainRaised,
  mrAuto,
  whiteColor,
  grayColor,
  mlAuto,
  blackColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

import buttonGroup from "assets/jss/material-kit-pro-react/buttonGroupStyle.js";
import tooltips from "assets/jss/material-kit-pro-react/tooltipsStyle.js";

const styles = {
  main,
  mainRaised: {
    ...mainRaised,
    marginTop : "80px",
    "@media (max-width: 576px)": {
      marginTop: "80px"
    },
  },
  mrAuto,
  mlAuto,
  cardTitle,
  ...buttonGroup,
  ...tooltips,
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor
    }
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block",
    "& img": {
      width: "100%"
    }
  },
  description: {
    maxWidth: "150px"
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em",
    maxWidth: "240px"
  },
  tdNameAnchor: {
    color: grayColor[1]
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300"
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "150px",
    fontWeight: "300",
    fontSize: "1.125em !important"
  },
  tdNumberSmall: {
    marginRight: "3px"
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0"
    }
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButton: {
    margin: "0px",
    padding: "5px"
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  },





  sectionSections: {
    ...section,
    padding: "70px 0px",
    paddingBottom: "0px",
    marginBottom: "-35px",
    "& img": {
      width: "100%",
      borderRadius: "3px",
      boxShadow:
        "0 8px 10px 1px rgba(" +
        hexToRgb(blackColor) +
        ",0.14), 0 3px 14px 2px rgba(" +
        hexToRgb(blackColor) +
        ",0.12), 0 5px 5px -3px rgba(" +
        hexToRgb(blackColor) +
        ",0.2)",
      transition: "all .2s cubic-bezier(0.4,0,0.2,1)",
      "&:hover": {
        transform: "scale(1.02)"
      }
    }
  },
  sectionDescription: {
    textAlign: "center",
    marginBottom: "60px"
  },
  title,
  description
};

export default styles;
