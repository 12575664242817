/*eslint-disable*/
import React from "react";
// Api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import SectionProduct from "./SectionProduct";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";

const useStyles = makeStyles(styles);

export default function SectionLatestOffers() {

  const { t } = useTranslation();
  const classes = useStyles();

  const [productModels, setProductModels] = React.useState([]);

  const handleLatestOffersRequest = async () => {
    try {
      const response = await api.post('/page/context/category?path=latest-offers&page=0', {});
      console.log('Post Response :', response.data);
      const productModels = response.data?.searchResponse?.products.slice(0, 3);
      setProductModels(productModels);
    } catch (error) {
      console.error('Post Error:', error);
    }
  };

  React.useEffect(() => {
    handleLatestOffersRequest();
  }, []);

  if (productModels?.length > 0) {
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <h2>{t('page.presentation.latestOffers')} </h2>
          <GridContainer>
            {productModels.map(p =>
              <GridItem md={4} sm={4} key={p.id}>
                <SectionProduct productModel={p} />
              </GridItem>
            )}
          </GridContainer>
        </div>
      </div>
    );
  }
  
  return null;

}