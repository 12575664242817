/*eslint-disable*/
import React from "react";
// I18N
import { useTranslation } from 'react-i18next';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import loadingPageStyle from "assets/jss/material-kit-pro-react/views/loadingPageStyles.js";

const useStyles = makeStyles(loadingPageStyle);

export default function LoadingPage() {

  /**Fixed For Each Page */
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  return (
    <div>
      <Header
        absolute
        //color=""
        brand={t('page.header.title')}
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div className={classes.pageHeader}>
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h2 className={classes.subTitle}> <i className="fas fa-spinner fa-spin" /></h2>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
