import React, { createContext, useReducer, useContext, useState } from "react";

// Başlangıç durumu
const initialState = {
  type: undefined,
  text: undefined,
  open: false
};

// Eylem türleri
const actionTypes = {
  OPEN_NOTIFY: "OPEN_NOTIFY",
  CLOSE_NOTIFY: "CLOSE_NOTIFY",
};

// Reducer fonksiyonu
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.OPEN_NOTIFY:
      return { open: true, type: action.payload.type, text: action.payload.text };
    case actionTypes.CLOSE_NOTIFY:
      return { open: false, type: state.type, text: state.text };
    default:
      return state;
  }
};

// Context oluşturulması
const NotifyContext = createContext();

// Provider bileşeni
const NotifyProvider = ({ children }) => {

  const [notifyState, dispatch] = useReducer(reducer, initialState);

  const openNotify = (type, text) => {
    dispatch({ type: actionTypes.OPEN_NOTIFY, payload: { text: text, type: type } });
  };

  const closeNotify = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: actionTypes.CLOSE_NOTIFY, payload: {} });
  };

  return (
    <NotifyContext.Provider value={{ notifyState, openNotify, closeNotify }}>
      {children}
    </NotifyContext.Provider>
  );
};

// Custom hook oluşturulması
const useNotify = () => {
  const context = useContext(NotifyContext);
  if (!context) {
    throw new Error("useNotify must be used within a NotifyProvider");
  }
  return context;
};

export { NotifyProvider, useNotify };