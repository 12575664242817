/*eslint-disable*/
import React from "react";
// api
import api from 'api';
// I18N
import { useTranslation } from 'react-i18next';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// sections for this page
import SectionProducts from "views/CategoryPage/Sections/SectionProducts.js";
import SectionFilters from "views/CategoryPage/Sections/SectionFilters.js";

// style
import styles from "assets/jss/material-kit-pro-react/views/categoryStyle.js";

const useStyles = makeStyles(styles);

export default function CategoryPage({ path, name, productModels, totalItems }) {

  /**Fixed For Each Page */
  React.useEffect(() => {
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { t } = useTranslation();
  /**Fixed For Each Page */

  const [filterResponse, setFilterResponse] = React.useState();
  const [categoryResponse, setCategoryResponse] = React.useState();

  const handlePageContextFilterRequest = async () => {
    try {
      const response = await api.get('/page/context/category/filter?path=' + path);
      console.log('Post Filter Response:', response.data);
      setFilterResponse(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Not Found Error:', error.response.data);
      } else {
        console.error('Post Error:', error);
      }
    }
  };

  const handlePageContextCategoryRequest = async () => {
    try {
      const response = await api.get('/page/context/category?path=' + path);
      console.log('Post Response:', response.data);
      setCategoryResponse(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error('Not Found Error:', error.response.data);
      } else {
        console.error('Post Error:', error);
      }
    }
  };

  React.useEffect(() => {
    if (path) {
      handlePageContextFilterRequest();
      handlePageContextCategoryRequest();
    }

  }, []);

  let resetProductPage = () => { };
  let resetTotalItems = () => { };

  const handleChangeFilter = (filter) => {
    resetProductPage(filter);
  }

  const handleResetTotalItems = (value) => {
    resetTotalItems(value);
  }

  return (
    <div>
      <Header
        brand={t('page.header.title')}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
      />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="/"
                  className={classes.block}
                >
                  {t('page.category.homePage')}
                </a>
              </ListItem>
              {
                categoryResponse?.parent && categoryResponse?.parent.url ?
                  <>
                    {categoryResponse?.parent?.parent && categoryResponse?.parent?.parent.url ?
                      <>
                        <ListItem className={classes.inlineBlock}>
                          <span  >{">"}</span>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                          <a
                            href="#"
                            className={classes.block}
                          >
                            ...
                          </a>
                        </ListItem>
                      </>
                      :
                      <></>
                    }
                    <ListItem className={classes.inlineBlock}>
                      <span  >{">"}</span>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <a
                        href={"/" + categoryResponse?.parent.url}
                        className={classes.block}
                      >
                        {categoryResponse.parent.name}
                      </a>
                    </ListItem>
                  </>
                  :
                  <>
                  </>
              }
              {
                categoryResponse ?
                  <>
                    <ListItem className={classes.inlineBlock}>
                      <span >{">"}</span>
                    </ListItem>
                    <ListItem className={classes.inlineBlock}>
                      <a
                        href="#"
                        className={classes.block}
                      >
                        {categoryResponse.name}
                      </a>
                    </ListItem>
                  </>
                  :
                  <></>
              }
            </List>
            <GridContainer>
              <GridItem md={3} sm={3}>
                <SectionFilters path={path} name={name} attributes={filterResponse?.attributes} properties={filterResponse?.propertyItems} brands={filterResponse?.brands} firstTotalItems={totalItems} categoryChild={categoryResponse?.children} handleChangeFilter={handleChangeFilter} forwardRefTotalItemsReset={c => { resetTotalItems = c }} />
              </GridItem>
              <GridItem md={9} sm={9}>
                <SectionProducts path={path} name={name} firstTotalItems={totalItems} firstProductModels={productModels} resetTotalItems={handleResetTotalItems} forwardRefReset={c => { resetProductPage = c }} />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
